import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "tm-item-object" }
const _hoisted_2 = { class: "item-details" }
const _hoisted_3 = { class: "item-header" }
const _hoisted_4 = { class: "item-sub-header" }
const _hoisted_5 = { class: "item-tm-detail-box" }
const _hoisted_6 = { class: "item-tm-detail-name" }
const _hoisted_7 = {
  key: 0,
  class: "item-tm-detail-role"
}
const _hoisted_8 = { class: "f-body-small item-tm-detail-role-text" }
const _hoisted_9 = { class: "item-notif-container" }
const _hoisted_10 = {
  key: 0,
  class: "item-expired"
}
const _hoisted_11 = {
  key: 1,
  class: "chart-container"
}
const _hoisted_12 = { class: "management-tm-bar-chart-header" }
const _hoisted_13 = {
  ref: "legendContainer",
  class: "flex-fill"
}
const _hoisted_14 = { class: "security-score" }
const _hoisted_15 = { class: "security-score-box f-body-extra-small" }
const _hoisted_16 = { class: "chart-code-issues" }
const _hoisted_17 = ["onClick", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_AlertBox = _resolveComponent("AlertBox")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_Bar = _resolveComponent("Bar")!
  const _component_SonarQube = _resolveComponent("SonarQube")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Avatar, {
              Name: _ctx.commitment.Name,
              Photo: _ctx.commitment.PhotoUrl,
              class: "item-tm-detail-avatar"
            }, null, 8, ["Name", "Photo"]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.commitment.Name), 1),
              (_ctx.commitment.JobRole && _ctx.commitment.JobRole != '')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _withDirectives((_openBlock(), _createBlock(_component_v_icon, { class: "item-tm-detail-icon" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.commitment.JobRoleIcon), 1)
                      ]),
                      _: 1
                    })), [
                      [_directive_tooltip, _ctx.commitment.JobRole]
                    ]),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.commitment.JobTitle && _ctx.commitment.JobTitle != "" ? _ctx.commitment.JobTitle : "-"), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          (!_ctx.commitment.IsCommitmentExpired)
            ? (_openBlock(), _createBlock(_component_AlertBox, {
                key: 0,
                commitment: _ctx.commitment
              }, null, 8, ["commitment"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_btn, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openCommitmentMenu(_ctx.commitment))),
            variant: "text",
            size: "small",
            icon: "mdi-cog",
            class: "item-tm-detail-setting"
          })
        ])
      ]),
      (_ctx.commitment.IsCommitmentExpired)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, "The Commitment of this teammember is Expired"))
        : _createCommentVNode("", true),
      ((_ctx.commitment.VmId || _ctx.commitment.CommitmentTimesheetProcessing) && !_ctx.commitment.IsCommitmentExpired)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, null, 512),
              _createElementVNode("div", _hoisted_14, [
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "f-body-small" }, "Security Score", -1)),
                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.commitment.SecurityScore), 1)
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_Bar, {
                data: _ctx.getBarChartData(_ctx.commitment),
                options: _ctx.barOptions,
                plugins: [_ctx.htmlLegendPlugin]
              }, null, 8, ["data", "options", "plugins"])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "chart-code-issues-title f-body-small" }, "Code Issues", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commitment.SonarqubeIssues, (dailyIssues) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["chart-code-issues-item f-body-extra-small", `${dailyIssues.MainIssue.Class}`]),
                  onClick: ($event: any) => (_ctx.showSonarQubeReport(_ctx.commitment.VmId, dailyIssues.Date)),
                  title: `${dailyIssues.MainIssue.IssuesCount} ${dailyIssues.MainIssue.Severaty} issue(s)`
                }, [
                  _createElementVNode("span", null, _toDisplayString(dailyIssues.MainIssue.IssuesCount), 1)
                ], 10, _hoisted_17))
              }), 256))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showSonarQubeReportDialog)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 0,
          persistent: "",
          modelValue: _ctx.showSonarQubeReportDialog,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showSonarQubeReportDialog) = $event)),
          fullscreen: "",
          transition: "dialog-bottom-transition",
          onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.showSonarQubeReportDialog = false), ["esc"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SonarQube, {
              vmId: _ctx.selectedVmId,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSonarQubeReportDialog = false)),
              isClient: true,
              initDate: _ctx.selectedSonarqubeDate
            }, null, 8, ["vmId", "initDate"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}