<template>
  <div>
    <div>
      <h2>Productivity</h2>
      <span class="f-body-medium c-silver-2">View And Manage your team Members productivity, see their hours</span>
    </div>
    <v-card :loading="loading" :disabled="loading" class="date-range-section">
      <DateRange :offset="dayOffset" @fetchData="fetchData" />
    </v-card>
    <div class="produ-teammember-section" v-if="teammembers && teammembers.length">
      <div class="tm-header-section">
        <h4>Team Members</h4>
        <h5 class="description">Select a team member to view timesheet reports</h5>
      </div>
    </div>
    <v-row v-if="teammembers && teammembers.length">
      <v-col cols="12" lg="4" v-for="(teammember, teammemberIndex) in teammembers" :key="`tmIndex_${teammemberIndex}`">
        <TeammemberItem
          :teammember="teammember"
          :line="getTeammemberCommitsTotal(teammember.id)"
          :hours="calculateBillableForTeammember(teammember.id)"
          @selectTm="selectUser(teammember.id)"
          :warnings="getTeammemberTimesheetIssues(teammember.id)"
          :infos="[]"
          :urgents="[]"
          :selectedTeammemberId="selectedTeammemberId" />
      </v-col>
    </v-row>
    <v-row class="mt-4 mb-4">
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>

    <div v-if="!tableLoading && teammembers && teammembers.length > 0">
      <h4>Portfolio</h4>
      <v-row class="mt-4">
        <v-col cols="12" lg="3" md="4" sm="6">
          <v-card>
            <v-card-text>
              <div class="dataBody">
                <h3>Projects</h3>
                <div class="chart">
                  <Pie :data="projectChartData" :options="getChartOptions(`project`)" />
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" md="4" sm="6">
          <v-card>
            <v-card-text>
              <div class="dataBody">
                <h3>Epics</h3>
                <div class="chart">
                  <Pie :data="epicChartData" :options="getChartOptions(`epic`)" />
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" md="4" sm="6">
          <v-card>
            <v-card-text>
              <div class="dataBody">
                <h3>Activities</h3>
                <div class="chart">
                  <Pie :data="acChartData" :options="getChartOptions(`activity`)" />
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" md="4" sm="6">
          <v-card>
            <v-card-text>
              <div class="dataBody">
                <h3>Technologies</h3>
                <div class="chart">
                  <Pie :data="techChartData" :options="getChartOptions(`technology`)" />
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-4 mb-4" v-if="clickedTeamMember">
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <div class="produ-teammember-section" v-if="clickedTeamMember">
      <div class="tm-header-section">
        <h4>Weekly Reports</h4>
        <h5 class="description">View Reports broken down by Dates, Team members, or Projects</h5>
      </div>
    </div>
    <v-row v-if="clickedTeamMember">
      <v-col cols="12">
        <div class="filter-container">
          <span class="f-body-medium c-silver-2">Filters:</span>
          <div class="filters">
            <div v-for="tag in filterItems" :key="tag.title" class="filter-item">
              {{ tag.title }}
              <v-icon class="clearChartFilter" @click="tag.removeFunction">mdi-close-circle</v-icon>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <h5 class="mb-6">Weekly Performance Reports</h5>

            <weekly-performance-report
              :key="`wpr_${weeklyPerformanceReportVersion}`"
              :teammember="clickedTeamMember"
              :timesheets="getTeammemberFilteredTimesheets(clickedTeamMember.id)" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="clickedTeamMember">
      <v-col cols="12">
        <v-card v-for="(listItem, index) in teammemberReport" :key="'row_' + index">
          <v-card-text>
            <div v-for="(weekItems, weekIndex) in listItem.weeklyReport" :key="'week_' + index + weekIndex" class="timesheet-report-card">
              <div class="tm-report-header">
                <div class="title">
                  <h5>Timesheet Report</h5>
                  <div class="d-flex ga-1">
                    <h5 class="c_silver_2">Week:</h5>
                    <span>{{ weekItems.week }}</span>
                  </div>
                </div>
              </div>
              <v-divider />
              <div v-for="(timesheetItem, timesheetIndex) in weekItems.timesheets" :key="'timesheet_' + index + weekIndex + timesheetIndex">
                <h6 class="mb-2" v-if="timesheetIndex === 0 || formatDate(weekItems.timesheets[timesheetIndex].Date) != formatDate(weekItems.timesheets[timesheetIndex - 1].Date)">
                  {{ timesheetItem.Date.toDateString() }}
                </h6>
                <v-card class="timesheet-report-item-card">
                  <v-card-text>
                    <div class="tm-rp-item-card-content">
                      <div class="tm-rp-item-card-first">
                        <div class="task-box">
                          <span class="f-body-medium-small">
                            {{ timesheetItem.IssueNo }}
                          </span>
                          <span class="f-body-medium-small">{{ removeDecimals(timesheetItem.Effort) }} Hour(s)</span>
                          <span class="f-body-medium-small" v-bind:style="[timesheetItem.Adjustment > 0 ? { color: '#4caf50' } : { color: 'red' }]" v-if="timesheetItem.Adjustment">
                            <span v-if="timesheetItem.Adjustment > 0">+</span>
                            {{ removeDecimals(timesheetItem.Adjustment) }} Hour(s)
                          </span>
                        </div>
                        <v-divider :vertical="!isMdAndDown()" />
                        <div class="detail-box">
                          <span>
                            <strong>
                              <v-icon class="c-silver-2" title="Project">mdi-briefcase</v-icon>
                              :
                            </strong>
                            {{ timesheetItem.Project.Name }}
                          </span>
                          <span>
                            <strong>
                              <v-icon class="c-silver-2" title="Epic">mdi-table-search</v-icon>
                              :
                            </strong>
                            {{ timesheetItem.Epic }}
                          </span>
                          <span>
                            <strong>
                              <v-icon class="c-silver-2" title="Activity">mdi-cog</v-icon>
                              :
                            </strong>
                            {{ timesheetItem.Activity }}
                          </span>
                          <span>
                            <strong>
                              <v-icon class="c-silver-2" title="Technology">mdi-lan</v-icon>
                              :
                            </strong>
                            {{ timesheetItem.Technology }}
                          </span>
                        </div>
                      </div>
                      <div class="tm-rp-item-card-last">
                        <span>{{ timesheetItem.Description }}</span>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="transparent" :loading="tableLoading">
      <div v-if="!tableLoading">
        <v-card-text v-if="!tableLoading && teammembers && teammembers.length > 0">
          <div style="clear: both" />
          <v-row v-if="clickedTeamMember">
            <!-- <v-col md="12" sm="12" cols="12" class="pl-6 pr-6 mt-5">
              <v-row>
                <v-col class="box-header" md="12" sm="12" cols="12">
                  <v-row>
                    <v-col md="4" sm="4" cols="12" class="text-center">
                      <strong>
                        GRAND TOTAL HOURS:
                        <span class="totalcalc">{{ removeDecimals(grandTotalHour) }}</span>
                      </strong>
                    </v-col>
                    <v-col md="4" sm="4" cols="12" class="text-center">
                      <strong>
                        GRAND TOTAL ADJUSTMENT:
                        <span class="totalcalc">{{ removeDecimals(grandTotalAdjustment) }}</span>
                      </strong>
                    </v-col>
                    <v-col md="4" sm="4" cols="12" class="text-center">
                      <strong>
                        GRAND TOTAL BILLABLE:
                        <span class="totalcalc">{{ removeDecimals(grandTotalBillable) }}</span>
                      </strong>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col> -->
          </v-row>
          <div v-if="!tableLoading && (!teammemberReport || teammemberReport.length === 0)" class="emptyResult d-flex justify-center mb-6">
            <h1 class="pa-md-16 ma-16">{{ message }}</h1>
          </div>
        </v-card-text>
      </div>
    </v-card>
  </div>
  <!-- <SighteFormsScript /> -->
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import Utils from "@/Helpers/Utils"
  import { DarkThemeColors, ProjectMessages } from "shared-components/src/definitions/constants"
  import { ProjectLeadTeammember } from "shared-components/src/models/ProjectLeadTeammember"
  import DateRange from "@/components/Range/DateRange.vue"
  import Timesheet from "shared-components/src/models/Timesheet"
  import ProfileAvatar from "@/components/Profile/ProfileAvatar.vue"
  import TimesheetChartReport from "@/components/Report/TimesheetChartReport.vue"
  import ProjectLeadReportService from "@/services/ProjectLeadReportService"
  import TimesheetService from "shared-components/src/services/TimesheetService"
  import TeammemberService from "@/services/TeammemberService"
  import ProjectLeadReport, { ActivityReport, TechnologyReport, EpicReport, ProjectReport, TeammemberVMReport } from "shared-components/src/models/ProjectLeadReport"
  import AppHelper from "shared-components/src/utils/AppHelper"
  import RepositoryTeammemberCommit from "shared-components/src/viewModels/response/repository/RepositoryTeammemberCommit"
  import RepositoryGitInsightRequest from "shared-components/src/viewModels/request/Repository/RepositoryGitInsightRequest"
  import SighteFormsScript from "@/components/Misc/SighteFormsScript.vue"
  import { Pie } from "vue-chartjs"
  import { PieChart } from "shared-components/src/models/PieChart"
  import WeeklyPerformanceReport from "./WeeklyPerformanceReport.vue"
  import TeammemberItem from "./TeammemberItem.vue"
  import moment from "moment"
  import { useDisplay } from "vuetify/lib/framework.mjs"
  interface FilterItem {
    title: string
    removeFunction: Function
  }

  interface TeammemberReport {
    teammemberName: string | null
    teammemberId: string | null
    weeklyReport: WeeklyReport[]
  }
  interface WeeklyReport {
    week: string
    timesheets: Timesheet[]
  }

  export interface UiTeammember extends ProjectLeadTeammember {
    isClicked: boolean | false
  }
  export default defineComponent({
    async mounted() {
      this.loading = true
      var teammemberList = await TeammemberService.getActiveTeammembers()
      this.allTeammembers = teammemberList as UiTeammember[]
    },
    components: {
      ProfileAvatar,
      TimesheetChartReport,
      DateRange,
      SighteFormsScript,
      Pie,
      WeeklyPerformanceReport,
      TeammemberItem,
    },
    data() {
      return {
        weeklyPerformanceReportVersion: 0,
        startDate: {} as Date,
        endDate: {} as Date,
        selectedTeammemberId: null as string | null,
        teammemberSelected: false,
        dataFilled: false,
        teammemberVMReports: [] as TeammemberVMReport[],
        activityReport: [] as ActivityReport[],
        technologyReport: [] as TechnologyReport[],
        projectLeadReport: {} as ProjectLeadReport,
        epicReport: [] as EpicReport[],
        projectReport: [] as ProjectReport[],
        allTeammembers: [] as UiTeammember[],
        teammembers: [] as UiTeammember[],
        timesheetReport: [] as Timesheet[],
        filteredTimesheetReport: [] as Timesheet[],
        teammemberReport: [] as TeammemberReport[],
        weeklyReport: [] as WeeklyReport[],
        loading: false,
        grandTotalHour: 0,
        grandTotalAdjustment: 0,
        grandTotalBillable: 0,
        tableLoading: false,
        dayOffset: -1,
        message: "",
        acChartData: {} as PieChart,
        techChartData: {} as PieChart,
        epicChartData: {} as PieChart,
        projectChartData: {} as PieChart,
        selectedActivity: "",
        selectedTechnology: "",
        selectedEpic: "",
        selectedProject: "",
        commit: {
          list: [] as RepositoryTeammemberCommit[],
          totalAdded: 0,
          totalModified: 0,
          totalDeleted: 0,
        },
        ProjectColorPalette: ["#601C87", "#7822AA", "#922CC9", "#A447D7", "#B568DE", "#C58AE5", "#D6ABED", "#E6CDF4", "#EEDDF8", "#F7EEFC"],
        EpicColorPalette: ["#7A2B00", "#A33900", "#DE5302", "#FD650D", "#FD7E35", "#FD985D", "#FEB286", "#FECCAE", "#FFE6D7", "#FFF2EB"],
        activityColorPalette: ["#101F94", "#1327B9", "#172EDE", "#4357EF", "#6979F2", "#8E9AF5", "#B4BCF8", "#DADEFC", "#ECEEFD", "#EDEFFD"],
        technologyColorPalette: ["#276568", "#328286", "#3D9FA4", "#4CB7BD", "#61BEC6", "#87CDD4", "#A5DADF", "#C3E7EA", "#E1F3F4", "#F0F9F9"],
      }
    },
    watch: {
      selectedTeammemberId() {
        this.filterReport()
      },
      selectedEpic(newVal) {
        this.filterReport()
      },
      selectedProject(newVal) {
        this.filterReport()
      },
      selectedTechnology(newVal) {
        this.filterReport()
      },
      selectedActivity(newVal) {
        this.filterReport()
      },
    },
    methods: {
      renderActivityReportChart(newVal: any) {
        var newData = newVal.filter((c: any) => c.name)
        this.activityReport = newData
        var labels = newData.map((c: any) => c.name)
        var data = newData.map((c: any) => c.netEffort)
        var backgroundColor = newData.map((c: any, index: any) => {
          return this.activityColorPalette[index]
        })
        this.acChartData = this.renderChart(labels, data, backgroundColor)
      },
      renderTechnologyReportChart(newVal: any) {
        var newData = newVal.filter((c: any) => c.name)
        this.technologyReport = newData
        var labels = newData.map((c: any) => c.name)
        var data = newData.map((c: any) => c.netEffort)
        var backgroundColor = newData.map((c: any, index: any) => {
          return this.technologyColorPalette[index]
        })
        this.techChartData = this.renderChart(labels, data, backgroundColor)
      },
      renderEpicReportChart(newVal: any) {
        var newData = newVal.filter((c: any) => c.name)
        this.epicReport = newData
        var labels = newData.map((c: any) => c.name)
        var data = newData.map((c: any) => c.netEffort)
        var backgroundColor = newData.map((c: any, index: any) => {
          return this.EpicColorPalette[index]
        })
        this.epicChartData = this.renderChart(labels, data, backgroundColor)
      },
      renderProjectReportChart(newVal: any) {
        var newData = newVal.filter((c: any) => c.name)
        this.projectReport = newData
        var labels = newData.map((c: any) => c.name)
        var data = newData.map((c: any) => c.netEffort)
        var backgroundColor = newData.map((c: any, index: any) => {
          return this.ProjectColorPalette[index]
        })
        this.projectChartData = this.renderChart(labels, data, backgroundColor)
      },
      getTeammemberTimesheetIssues(teammemberId: string) {
        return TimesheetService.TimesheetReviewResultForTeammember(this.getTeammemberTimesheets(teammemberId), this.getTeammemberCommits(teammemberId))
      },
      getTeammemberCommitsTotal(teammemberId: string) {
        var commits = this.getTeammemberCommits(teammemberId)
        return commits.map((item) => item.linesChanged).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      },
      getVmUsage(teammemberId: string) {
        return this.teammemberVMReports?.find((x) => x.tmId == teammemberId)?.netEffort ?? null
      },
      getTeammemberCommits(teammemberId: string) {
        return this.commit.list.filter((cm) => cm.TeamMemberId === teammemberId)
      },
      getTeammemberNameById(teammemberId: string): UiTeammember | null {
        const tm = this.teammembers.find((tm) => tm.id === teammemberId)
        return tm as UiTeammember | null
      },
      getTeammemberFilteredTimesheets(teammemberId: string) {
        return this.filteredTimesheetReport.filter((tr) => tr.TeamMemberId === teammemberId)
      },
      getTeammemberTimesheets(teammemberId: string) {
        return this.timesheetReport.filter((tr) => tr.TeamMemberId === teammemberId)
      },
      async setCommitListByProjectLead() {
        try {
          this.commit.list = []
          const model = {
            EndDate: this.endDate,
            StartDate: this.startDate,
            TeammemberIds: this.teammembers.map((x) => x.id),
          } as RepositoryGitInsightRequest
          this.commit.list = await ProjectLeadReportService.GetCommitListByProjectLead(model)
          let totalAdded = 0
          let totalModified = 0
          let totalDeleted = 0
          if (this.commit.list) {
            this.commit.list.forEach((commit) => {
              totalAdded += commit.linesAdded
              totalModified += commit.linesChanged
              totalDeleted += commit.linesDeleted
            })
          }

          this.commit.totalAdded = totalAdded
          this.commit.totalModified = totalModified
          this.commit.totalDeleted = totalDeleted
        } catch (error: any) {
          const errorMessage = error && error.response && error.response.data
        }
      },
      getChartOptions(chartType: string) {
        let onClick = this.handleEpicChartClick
        switch (chartType) {
          case "epic":
            onClick = this.handleEpicChartClick
            break
          case "activity":
            onClick = this.handleActivityChartClick
            break
          case "technology":
            onClick = this.handleTechnologyChartClick
            break
          case "project":
            onClick = this.handleProjectChartClick
            break
        }
        return {
          responsive: true,
          maintainAspectRatio: false,
          onClick,
          plugins: {
            legend: {
              display: false,
              position: "bottom",
              align: "start",
              labels: {
                color: DarkThemeColors.Primary,
              },
            },
          },
        }
      },
      handleEpicChartClick(event: any, chartElements: any) {
        this.selectedEpic = this.handleChartClick(event, chartElements, this.epicChartData)
      },
      handleProjectChartClick(event: any, chartElements: any) {
        var selectedLabel = this.handleChartClick(event, chartElements, this.projectChartData)
        this.selectedProject = this.getProjectIdByName(selectedLabel)
      },
      getTechnologyIdByName(technologyName: string) {
        const technology = this.technologyReport.find((x) => technologyName && x.name === technologyName)
        return technology ? technology!.id : ""
      },
      getTechnologyNameById(technologyId: string) {
        const technology = this.technologyReport.find((x) => technologyId && x.id === technologyId)
        return technology ? technology!.name : ""
      },
      getProjectIdByName(ProjectName: string) {
        const project = this.projectReport.find((x) => ProjectName && x.name === ProjectName)
        return project ? project!.id : ""
      },
      getProjectNameById(ProjectId: string) {
        const project = this.projectReport.find((x) => ProjectId && x.id === ProjectId)
        return project ? project!.name : ""
      },
      handleTechnologyChartClick(event: any, chartElements: any) {
        var selectedLabel = this.handleChartClick(event, chartElements, this.techChartData)
        this.selectedTechnology = this.getTechnologyIdByName(selectedLabel)
      },
      getActivityIdByName(activityName: string) {
        const activity = this.activityReport.find((x) => activityName && x.name === activityName)
        return activity ? activity!.id : ""
      },
      getActivityNameById(activityId: string) {
        const activity = this.activityReport.find((x) => activityId && x.id === activityId)
        return activity ? activity!.name : ""
      },
      handleActivityChartClick(event: any, chartElements: any) {
        var selectedLabel = this.handleChartClick(event, chartElements, this.acChartData)
        this.selectedActivity = this.getActivityIdByName(selectedLabel)
      },
      handleChartClick(event: any, chartElements: any, chartData: PieChart) {
        if (chartElements.length != 0) {
          var position = chartElements[0].index
          return chartData.labels[position]
        } else {
          return ""
        }
      },
      clearSelectedTeammemberAndFillAll(selectedTeammemberId: string | null = null) {
        if (selectedTeammemberId) {
          this.selectedTeammemberId = this.teammembers.filter((tm) => tm.id == selectedTeammemberId)[0].id
        } else {
          this.selectedTeammemberId = null
          this.filterReport()
        }

        this.teammembers.forEach((tm) => {
          tm.isClicked = false
        })
      },
      clearSelectedEpic() {
        this.selectedEpic = ""
      },
      clearSelectedActivity() {
        this.selectedActivity = ""
      },
      clearSelectedTechnology() {
        this.selectedTechnology = ""
      },
      clearSelectedProject() {
        this.selectedProject = ""
      },
      renderChart(labels: string[], datas: string[], backgroundcolors: string[]) {
        var newConfigs = {
          labels: labels,
          datasets: [
            {
              backgroundColor: backgroundcolors,
              data: datas,
              hoverOffset: 4,
            },
          ],
        }
        return newConfigs as PieChart
      },
      formatDate(date: Date | null): string {
        if (date) {
          return Utils.toVsDateFormat(date)
        } else {
          return "Not set"
        }
      },
      calculateTotalHours(list: any) {
        const timesheets = list.filter((c: any) => c.timesheets).map((item: any) => item.timesheets)
        list = []
        timesheets.forEach((c: any) => {
          if (c) {
            list = list.concat(c)
          }
        })
        return this.calculateHours(list)
      },
      calculateTotalAdjustment(list: any) {
        const timesheets = list.filter((c: any) => c.timesheets).map((item: any) => item.timesheets)
        list = []
        timesheets.forEach((c: any) => {
          if (c) {
            list = list.concat(c)
          }
        })
        return this.calculateAdjustment(list)
      },
      calculateTotalBillable(list: any) {
        const timesheets = list.filter((c: any) => c.timesheets).map((item: any) => item.timesheets)
        list = []
        timesheets.forEach((c: any) => {
          if (c) {
            list = list.concat(c)
          }
        })
        return this.calculateBillable(list)
      },

      calculateHours(list: Timesheet[]) {
        if (list && list.length > 0) {
          const timesheetList = list.filter((c) => c.ProjectId && (c.Activity || c.ActivityId) && c.Epic)
          if (timesheetList && timesheetList.length > 0) {
            let totalHours = timesheetList.map((item) => item.Effort).reduce((prev, next) => prev + next)
            totalHours = this.removeDecimals(totalHours)
            return totalHours
          }
        }
        return 0
      },
      calculateAdjustment(list: Timesheet[]) {
        if (list && list.length > 0) {
          const timesheetList = list.filter((c) => c.ProjectId && (c.Activity || c.ActivityId) && c.Epic)
          if (timesheetList && timesheetList.length > 0) {
            let totalAdjustment = timesheetList.map((item) => item.Adjustment).reduce((prev, next) => prev + next)
            totalAdjustment = this.removeDecimals(totalAdjustment)
            return totalAdjustment
          }
        }
        return 0
      },
      calculateBillableForTeammember(teammemberId: string): number {
        if (!this.teammemberReport) return 0
        var tmListItem = this.teammemberReport.find((x) => x.teammemberId == teammemberId)
        if (!tmListItem || !tmListItem.weeklyReport) return 0
        return this.calculateTotalBillable(tmListItem.weeklyReport)
      },
      calculateBillable(list: Timesheet[]) {
        if (list && list.length > 0) {
          const timesheetList = list.filter((c) => c.ProjectId && (c.Activity || c.ActivityId) && c.Epic)
          if (timesheetList && timesheetList.length > 0) {
            let totalHours = timesheetList.map((item) => item.Effort).reduce((prev, next) => prev + next)
            let totalAdjustment = timesheetList.map((item) => item.Adjustment).reduce((prev, next) => prev + next)
            totalHours = this.removeDecimals(totalHours)
            totalAdjustment = this.removeDecimals(totalAdjustment)
            let totalBillable = totalHours + totalAdjustment
            totalBillable = this.removeDecimals(totalBillable)
            return totalBillable
          }
        }
        return 0
      },

      removeDecimals(value: any) {
        return AppHelper.removeDecimals(value)
      },
      getDatesBetweenDates() {
        let dates = [] as Date[]
        const theDate = new Date(this.startDate)
        while (theDate < this.endDate) {
          dates = [...dates, new Date(theDate)]
          theDate.setDate(theDate.getDate() + 1)
        }
        dates = [...dates, this.endDate]
        return dates
      },
      getStartandEndOfWeek() {
        const firstday = Utils.toVsDateFormat(this.startDate)
        const lastday = Utils.toVsDateFormat(this.endDate)
        return `${firstday} ~ ${lastday}`
      },
      getTeammemberTimesheetInWeek(teammemberId: any) {
        const timesheetsInWeek = [] as WeeklyReport[]

        this.weeklyReport.forEach((wr) => {
          const timesheetList = this.filteredTimesheetReport.filter((tr) => tr.TeamMemberId === teammemberId && tr.Date && tr.Date >= this.startDate && tr.Date <= this.endDate)
          timesheetList.forEach((c) => {
            c.Effort = this.removeDecimals(c.Effort)
            if (c.ProjectId && (c.Activity || c.ActivityId) && c.Epic) {
              this.grandTotalHour += this.removeDecimals(c.Effort)
              this.grandTotalAdjustment += this.removeDecimals(c.Adjustment)
            }
          })
          timesheetsInWeek.push({ week: wr.week, timesheets: timesheetList })
        })
        return timesheetsInWeek
      },
      async fetchTimesheetReport(selectedTeammemberId: string | null = null) {
        if (!selectedTeammemberId) {
          this.teammemberSelected = false
        }
        this.activityReport = [] as ActivityReport[]
        this.technologyReport = [] as TechnologyReport[]
        this.epicReport = [] as EpicReport[]
        this.projectReport = [] as ProjectReport[]
        this.loading = true
        this.tableLoading = true
        this.weeklyReport = []
        this.teammemberReport = []
        this.projectLeadReport = await ProjectLeadReportService.getList(this.startDate, this.endDate)

        this.teammemberVMReports = this.projectLeadReport.teammemberVMReport
        await this.setCommitListByProjectLead()
        this.activityReport = this.projectLeadReport.activityReport
        this.technologyReport = this.projectLeadReport.technologyReport
        this.epicReport = this.projectLeadReport.epicReport
        this.projectReport = this.projectLeadReport.projectReport
        this.dataFilled = true

        this.timesheetReport = this.projectLeadReport.timesheetReport
        this.filteredTimesheetReport = this.projectLeadReport.timesheetReport
        this.selectedActivity = ""
        this.selectedEpic = ""
        this.selectedTechnology = ""
        if (this.timesheetReport && this.timesheetReport.length > 0 && this.allTeammembers && this.allTeammembers.length > 0) {
          // filter team members that logged timesheet
          const uniqueTeammemberIds = [...new Set(this.timesheetReport.map((item) => item.TeamMemberId))]
          this.teammembers = this.allTeammembers.filter((x) => uniqueTeammemberIds.includes(x.id))
        } else {
          this.teammembers = []
        }
        // set teammembers as clicked
        this.clearSelectedTeammemberAndFillAll(selectedTeammemberId)

        this.loading = false
        this.tableLoading = false
      },
      calculateActivities() {
        let activities = this.filteredTimesheetReport.map((c) => {
          return {
            id: c.ActivityId,
            name: c.Activity,
            netEffort: c.Effort + c.Adjustment,
          } as ActivityReport
        })
        this.activityReport = activities.reduce((ac: ActivityReport[], a) => {
          let ind = ac.findIndex((x) => x.id === a.id)
          ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort)
          return ac
        }, [])

        this.renderActivityReportChart(this.activityReport)
      },
      calculateTechnologies() {
        let technologies = this.filteredTimesheetReport.map((c) => {
          return {
            id: c.TechnologyId,
            name: c.Technology,
            netEffort: c.Effort + c.Adjustment,
          } as TechnologyReport
        })
        this.technologyReport = technologies.reduce((ac: TechnologyReport[], a) => {
          let ind = ac.findIndex((x) => x.id === a.id)
          ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort)
          return ac
        }, [])

        this.renderTechnologyReportChart(this.technologyReport)
      },
      calculateProjects() {
        let projects = this.filteredTimesheetReport.map((c) => {
          return {
            id: c.ProjectId,
            name: c.Project?.Name,
            netEffort: c.Effort + c.Adjustment,
          } as ProjectReport
        })
        this.projectReport = projects.reduce((ac: ProjectReport[], a) => {
          let ind = ac.findIndex((x) => x.id === a.id)
          ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort)
          return ac
        }, [])

        this.renderProjectReportChart(this.projectReport)
      },
      calculateEpics() {
        let epics = this.filteredTimesheetReport.map((c) => {
          return {
            name: c.Epic,
            netEffort: c.Effort + c.Adjustment,
          } as EpicReport
        })
        this.epicReport = epics.reduce((ac: EpicReport[], a) => {
          let ind = ac.findIndex((x) => x.name === a.name)
          ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort)
          return ac
        }, [])

        this.renderEpicReportChart(this.epicReport)
      },
      filterReport() {
        this.message = ""
        this.grandTotalAdjustment = 0
        this.grandTotalHour = 0
        this.grandTotalBillable = 0
        this.teammemberReport = [] as TeammemberReport[]
        if (this.timesheetReport && this.timesheetReport.length > 0) {
          if (this.selectedTeammemberId) {
            this.filteredTimesheetReport = this.timesheetReport.filter((c) => c.TeamMemberId && this.selectedTeammemberId == c.TeamMemberId)
          } else {
            this.filteredTimesheetReport = this.timesheetReport
          }

          if (this.selectedActivity) {
            this.filteredTimesheetReport = this.filteredTimesheetReport.filter((x) => x.ActivityId == this.selectedActivity)
          }

          if (this.selectedEpic) {
            this.filteredTimesheetReport = this.filteredTimesheetReport.filter((x) => x.Epic == this.selectedEpic)
          }

          if (this.selectedTechnology) {
            this.filteredTimesheetReport = this.filteredTimesheetReport.filter((x) => x.TechnologyId == this.selectedTechnology)
          }

          if (this.selectedProject) {
            this.filteredTimesheetReport = this.filteredTimesheetReport.filter((x) => x.ProjectId == this.selectedProject)
          }
          const selectedTimesheetTeammembers = this.filteredTimesheetReport.map((tr) => tr.TeamMemberId)
          const uniqueTeammemberIds = [...new Set(selectedTimesheetTeammembers)]
          const datesArray = this.getDatesBetweenDates()
          datesArray.forEach((da) => {
            const weekDates = this.getStartandEndOfWeek()
            const exist = this.weeklyReport.some((wr) => wr.week === weekDates)
            if (!exist) {
              this.weeklyReport.push({ week: weekDates, timesheets: [] })
            }
          })

          if (uniqueTeammemberIds) {
            uniqueTeammemberIds.forEach((ut) => {
              const timesheet = this.filteredTimesheetReport.filter((t) => t.TeamMemberId === ut)[0]
              this.teammemberReport.push({
                teammemberId: ut,
                teammemberName: timesheet.TeammemberName,
                weeklyReport: this.getTeammemberTimesheetInWeek(ut),
              })
            })
            if (!this.teammemberReport || this.teammemberReport.length === 0) {
              this.message = ProjectMessages.NoTimesheet
            }
            this.calculateActivities()
            this.calculateTechnologies()
            this.calculateEpics()
            this.calculateProjects()
          }
        } else {
          this.message = ProjectMessages.NoTimesheet
        }
        this.grandTotalBillable = this.removeDecimals(this.grandTotalHour + this.grandTotalAdjustment)
      },
      selectUser(tmId: any) {
        if (tmId != null) {
          this.teammemberSelected = true
        } else {
          this.teammemberSelected = false
        }
        const tm = this.teammembers.find((x) => x.id == tmId)
        if (this.selectedTeammemberId == tmId && tm && !tm.isClicked) {
          this.selectedTeammemberId = null
        }
        if (this.selectedTeammemberId != tmId && tm && tm.isClicked) {
          this.selectedTeammemberId = tmId
        }
      },

      setDate(startDate: Date, endDate: Date) {
        this.startDate = startDate
        this.endDate = endDate
      },
      async fetchData(dateModel: { startDate: Date; endDate: Date }) {
        this.setDate(dateModel.startDate, dateModel.endDate)
        //this.setDate(moment("2025-02-03").toDate(), moment("2025-02-10").toDate())
        await this.fetchTimesheetReport(this.clickedTeamMember?.id)
      },
      isMdAndDown() {
        const { mdAndDown } = useDisplay()
        return mdAndDown.value
      },
    },
    computed: {
      clickedTeamMember(): UiTeammember | null {
        if (this.selectedTeammemberId && this.teammemberSelected) {
          return this.getTeammemberNameById(this.selectedTeammemberId)
        }
        return null
      },
      filterItems(): FilterItem[] {
        const filterItems = [] as FilterItem[]

        // check selected team member
        if (this.clickedTeamMember) {
          filterItems.push({
            title: `${this.clickedTeamMember.fullName}`,
            removeFunction: this.clearSelectedTeammemberAndFillAll,
          } as FilterItem)
        }

        // check selected epic
        if (this.selectedEpic) {
          filterItems.push({
            title: `${this.selectedEpic}`,
            removeFunction: this.clearSelectedEpic,
          } as FilterItem)
        }
        // check selected technology
        if (this.selectedTechnology) {
          filterItems.push({
            title: `${this.getTechnologyNameById(this.selectedTechnology)}`,
            removeFunction: this.clearSelectedTechnology,
          } as FilterItem)
        }
        // check selected project
        if (this.selectedProject) {
          filterItems.push({
            title: `${this.getProjectNameById(this.selectedProject)}`,
            removeFunction: this.clearSelectedProject,
          } as FilterItem)
        }

        // check selected activity
        if (this.selectedActivity) {
          filterItems.push({
            title: `${this.getActivityNameById(this.selectedActivity)}`,
            removeFunction: this.clearSelectedActivity,
          } as FilterItem)
        }
        return filterItems
      },
    },
  })
</script>
<style scoped lang="scss">
  @import "node_modules/shared-components/assets/style/style.scss";

  .produ-teammember-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
    .tm-header-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .description {
        color: $c_jordy_blue !important;
      }
    }
  }
  .date-range-section {
    margin-top: 24px;
  }

  .transparent {
    background-color: transparent !important;
    box-shadow: none;
    border: none;
  }
  .emptyResult h1 {
    text-align: center;
  }
  .text-flex-container {
    display: flex;
    align-items: center;
  }

  .text-flex-container .content {
    flex: 1;
  }

  .dataBody {
    height: 350px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .chart {
      flex: 1;
    }
  }

  .filter-container {
    @extend .b-r-5;
    background-color: $c_eerie_black;
    padding: 8px 16px;
    display: flex;
    gap: 16px;
    align-items: center;
    .filters {
      display: flex;
      gap: 8px;
      align-items: center;
      flex-wrap: wrap;
      .filter-item {
        @extend .b-r-10;
        padding: 4px 8px;
        background-color: $c_jet;
        color: $c_white;
        .clearChartFilter {
          cursor: pointer;
        }
      }
    }
  }

  .timesheet-report-card {
    display: flex;
    gap: 16px;
    flex-direction: column;
    .tm-report-header {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      .title {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }

    .timesheet-report-item-card {
      background: $c_jet_3 !important;
      border: 1px solid $c_dim_gray;
      .v-card-text {
        padding: 16px !important;
      }
      .tm-rp-item-card-content {
        display: flex;
        gap: 16px;
        .tm-rp-item-card-first {
          display: flex;
          gap: 16px;
          width: 60%;
          .task-box {
            min-width: 110px;
            background-color: $c_jet;
            @extend .b-r-5;
            padding: 8px 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .detail-box {
            gap: 16px;
            display: flex;
            flex-direction: column;
          }
        }
        .tm-rp-item-card-last {
          width: 40%;
          background-color: $c_jet;
          @extend .b-r-5;
          padding: 8px;
        }
      }
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .produ-teammember-section {
      .tm-header-section {
        flex-direction: column;
        align-items: start;
        gap: 24px;
      }
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .tm-rp-item-card-content {
      flex-direction: column;
      .tm-rp-item-card-first {
        flex-direction: column;
        width: 100% !important;
      }
      .tm-rp-item-card-last {
        width: 100% !important;
        min-height: 112px;
      }
    }
  }
</style>
