import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "produ-teammember-section"
}
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "dataBody" }
const _hoisted_4 = { class: "chart" }
const _hoisted_5 = { class: "dataBody" }
const _hoisted_6 = { class: "chart" }
const _hoisted_7 = { class: "dataBody" }
const _hoisted_8 = { class: "chart" }
const _hoisted_9 = { class: "dataBody" }
const _hoisted_10 = { class: "chart" }
const _hoisted_11 = {
  key: 4,
  class: "produ-teammember-section"
}
const _hoisted_12 = { class: "filter-container" }
const _hoisted_13 = { class: "filters" }
const _hoisted_14 = { class: "tm-report-header" }
const _hoisted_15 = { class: "title" }
const _hoisted_16 = { class: "d-flex ga-1" }
const _hoisted_17 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_18 = { class: "tm-rp-item-card-content" }
const _hoisted_19 = { class: "tm-rp-item-card-first" }
const _hoisted_20 = { class: "task-box" }
const _hoisted_21 = { class: "f-body-medium-small" }
const _hoisted_22 = { class: "f-body-medium-small" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "detail-box" }
const _hoisted_25 = { class: "tm-rp-item-card-last" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = {
  key: 1,
  class: "emptyResult d-flex justify-center mb-6"
}
const _hoisted_28 = { class: "pa-md-16 ma-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateRange = _resolveComponent("DateRange")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_TeammemberItem = _resolveComponent("TeammemberItem")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_Pie = _resolveComponent("Pie")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_weekly_performance_report = _resolveComponent("weekly-performance-report")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[21] || (_cache[21] = _createElementVNode("div", null, [
      _createElementVNode("h2", null, "Productivity"),
      _createElementVNode("span", { class: "f-body-medium c-silver-2" }, "View And Manage your team Members productivity, see their hours")
    ], -1)),
    _createVNode(_component_v_card, {
      loading: _ctx.loading,
      disabled: _ctx.loading,
      class: "date-range-section"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DateRange, {
          offset: _ctx.dayOffset,
          onFetchData: _ctx.fetchData
        }, null, 8, ["offset", "onFetchData"])
      ]),
      _: 1
    }, 8, ["loading", "disabled"]),
    (_ctx.teammembers && _ctx.teammembers.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "tm-header-section" }, [
            _createElementVNode("h4", null, "Team Members"),
            _createElementVNode("h5", { class: "description" }, "Select a team member to view timesheet reports")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.teammembers && _ctx.teammembers.length)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teammembers, (teammember, teammemberIndex) => {
              return (_openBlock(), _createBlock(_component_v_col, {
                cols: "12",
                lg: "4",
                key: `tmIndex_${teammemberIndex}`
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TeammemberItem, {
                    teammember: teammember,
                    line: _ctx.getTeammemberCommitsTotal(teammember.id),
                    hours: _ctx.calculateBillableForTeammember(teammember.id),
                    onSelectTm: ($event: any) => (_ctx.selectUser(teammember.id)),
                    warnings: _ctx.getTeammemberTimesheetIssues(teammember.id),
                    infos: [],
                    urgents: [],
                    selectedTeammemberId: _ctx.selectedTeammemberId
                  }, null, 8, ["teammember", "line", "hours", "onSelectTm", "warnings", "selectedTeammemberId"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_row, { class: "mt-4 mb-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_divider)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (!_ctx.tableLoading && _ctx.teammembers && _ctx.teammembers.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("h4", null, "Portfolio", -1)),
          _createVNode(_component_v_row, { class: "mt-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                lg: "3",
                md: "4",
                sm: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, [
                            _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Projects", -1)),
                            _createElementVNode("div", _hoisted_4, [
                              _createVNode(_component_Pie, {
                                data: _ctx.projectChartData,
                                options: _ctx.getChartOptions(`project`)
                              }, null, 8, ["data", "options"])
                            ])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                lg: "3",
                md: "4",
                sm: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Epics", -1)),
                            _createElementVNode("div", _hoisted_6, [
                              _createVNode(_component_Pie, {
                                data: _ctx.epicChartData,
                                options: _ctx.getChartOptions(`epic`)
                              }, null, 8, ["data", "options"])
                            ])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                lg: "3",
                md: "4",
                sm: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Activities", -1)),
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_Pie, {
                                data: _ctx.acChartData,
                                options: _ctx.getChartOptions(`activity`)
                              }, null, 8, ["data", "options"])
                            ])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                lg: "3",
                md: "4",
                sm: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, [
                            _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Technologies", -1)),
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_Pie, {
                                data: _ctx.techChartData,
                                options: _ctx.getChartOptions(`technology`)
                              }, null, 8, ["data", "options"])
                            ])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.clickedTeamMember)
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 3,
          class: "mt-4 mb-4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_divider)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.clickedTeamMember)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[6] || (_cache[6] = [
          _createElementVNode("div", { class: "tm-header-section" }, [
            _createElementVNode("h4", null, "Weekly Reports"),
            _createElementVNode("h5", { class: "description" }, "View Reports broken down by Dates, Team members, or Projects")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.clickedTeamMember)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 5 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_12, [
                  _cache[8] || (_cache[8] = _createElementVNode("span", { class: "f-body-medium c-silver-2" }, "Filters:", -1)),
                  _createElementVNode("div", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterItems, (tag) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: tag.title,
                        class: "filter-item"
                      }, [
                        _createTextVNode(_toDisplayString(tag.title) + " ", 1),
                        _createVNode(_component_v_icon, {
                          class: "clearChartFilter",
                          onClick: tag.removeFunction
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("mdi-close-circle")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ]))
                    }), 128))
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _cache[9] || (_cache[9] = _createElementVNode("h5", { class: "mb-6" }, "Weekly Performance Reports", -1)),
                        (_openBlock(), _createBlock(_component_weekly_performance_report, {
                          key: `wpr_${_ctx.weeklyPerformanceReportVersion}`,
                          teammember: _ctx.clickedTeamMember,
                          timesheets: _ctx.getTeammemberFilteredTimesheets(_ctx.clickedTeamMember.id)
                        }, null, 8, ["teammember", "timesheets"]))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.clickedTeamMember)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 6 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teammemberReport, (listItem, index) => {
                  return (_openBlock(), _createBlock(_component_v_card, {
                    key: 'row_' + index
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listItem.weeklyReport, (weekItems, weekIndex) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: 'week_' + index + weekIndex,
                              class: "timesheet-report-card"
                            }, [
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("div", _hoisted_15, [
                                  _cache[11] || (_cache[11] = _createElementVNode("h5", null, "Timesheet Report", -1)),
                                  _createElementVNode("div", _hoisted_16, [
                                    _cache[10] || (_cache[10] = _createElementVNode("h5", { class: "c_silver_2" }, "Week:", -1)),
                                    _createElementVNode("span", null, _toDisplayString(weekItems.week), 1)
                                  ])
                                ])
                              ]),
                              _createVNode(_component_v_divider),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(weekItems.timesheets, (timesheetItem, timesheetIndex) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: 'timesheet_' + index + weekIndex + timesheetIndex
                                }, [
                                  (timesheetIndex === 0 || _ctx.formatDate(weekItems.timesheets[timesheetIndex].Date) != _ctx.formatDate(weekItems.timesheets[timesheetIndex - 1].Date))
                                    ? (_openBlock(), _createElementBlock("h6", _hoisted_17, _toDisplayString(timesheetItem.Date.toDateString()), 1))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_v_card, { class: "timesheet-report-item-card" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card_text, null, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_18, [
                                            _createElementVNode("div", _hoisted_19, [
                                              _createElementVNode("div", _hoisted_20, [
                                                _createElementVNode("span", _hoisted_21, _toDisplayString(timesheetItem.IssueNo), 1),
                                                _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.removeDecimals(timesheetItem.Effort)) + " Hour(s)", 1),
                                                (timesheetItem.Adjustment)
                                                  ? (_openBlock(), _createElementBlock("span", {
                                                      key: 0,
                                                      class: "f-body-medium-small",
                                                      style: _normalizeStyle([timesheetItem.Adjustment > 0 ? { color: '#4caf50' } : { color: 'red' }])
                                                    }, [
                                                      (timesheetItem.Adjustment > 0)
                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_23, "+"))
                                                        : _createCommentVNode("", true),
                                                      _createTextVNode(" " + _toDisplayString(_ctx.removeDecimals(timesheetItem.Adjustment)) + " Hour(s) ", 1)
                                                    ], 4))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _createVNode(_component_v_divider, {
                                                vertical: !_ctx.isMdAndDown()
                                              }, null, 8, ["vertical"]),
                                              _createElementVNode("div", _hoisted_24, [
                                                _createElementVNode("span", null, [
                                                  _createElementVNode("strong", null, [
                                                    _createVNode(_component_v_icon, {
                                                      class: "c-silver-2",
                                                      title: "Project"
                                                    }, {
                                                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                                                        _createTextVNode("mdi-briefcase")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _cache[13] || (_cache[13] = _createTextVNode(" : "))
                                                  ]),
                                                  _createTextVNode(" " + _toDisplayString(timesheetItem.Project.Name), 1)
                                                ]),
                                                _createElementVNode("span", null, [
                                                  _createElementVNode("strong", null, [
                                                    _createVNode(_component_v_icon, {
                                                      class: "c-silver-2",
                                                      title: "Epic"
                                                    }, {
                                                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                                                        _createTextVNode("mdi-table-search")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _cache[15] || (_cache[15] = _createTextVNode(" : "))
                                                  ]),
                                                  _createTextVNode(" " + _toDisplayString(timesheetItem.Epic), 1)
                                                ]),
                                                _createElementVNode("span", null, [
                                                  _createElementVNode("strong", null, [
                                                    _createVNode(_component_v_icon, {
                                                      class: "c-silver-2",
                                                      title: "Activity"
                                                    }, {
                                                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                                                        _createTextVNode("mdi-cog")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _cache[17] || (_cache[17] = _createTextVNode(" : "))
                                                  ]),
                                                  _createTextVNode(" " + _toDisplayString(timesheetItem.Activity), 1)
                                                ]),
                                                _createElementVNode("span", null, [
                                                  _createElementVNode("strong", null, [
                                                    _createVNode(_component_v_icon, {
                                                      class: "c-silver-2",
                                                      title: "Technology"
                                                    }, {
                                                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                        _createTextVNode("mdi-lan")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _cache[19] || (_cache[19] = _createTextVNode(" : "))
                                                  ]),
                                                  _createTextVNode(" " + _toDisplayString(timesheetItem.Technology), 1)
                                                ])
                                              ])
                                            ]),
                                            _createElementVNode("div", _hoisted_25, [
                                              _createElementVNode("span", null, _toDisplayString(timesheetItem.Description), 1)
                                            ])
                                          ])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              }), 128))
                            ]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_card, {
      class: "transparent",
      loading: _ctx.tableLoading
    }, {
      default: _withCtx(() => [
        (!_ctx.tableLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
              (!_ctx.tableLoading && _ctx.teammembers && _ctx.teammembers.length > 0)
                ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                    default: _withCtx(() => [
                      _cache[20] || (_cache[20] = _createElementVNode("div", { style: {"clear":"both"} }, null, -1)),
                      (_ctx.clickedTeamMember)
                        ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }))
                        : _createCommentVNode("", true),
                      (!_ctx.tableLoading && (!_ctx.teammemberReport || _ctx.teammemberReport.length === 0))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            _createElementVNode("h1", _hoisted_28, _toDisplayString(_ctx.message), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["loading"])
  ]))
}