<template>
  <div>
    <div class="cost-header-container">
      <div class="cost-header-title">
        <h2>Cost</h2>
        <span class="f-body-medium c-silver-2">View and Manage your Project Costs</span>
      </div>
      <v-divider :thickness="2" class="cost-divider" />
      <div class="cost-header-details">
        <div class="cost-header-details-item">
          <span class="f-body-medium c-silver-2">Total Cost (Ex. Tax)</span>
          <v-progress-circular color="primary" indeterminate v-if="loading" />
          <div v-else>
            <div v-if="getTotalCost.length == 0" class="cost-header-details-item-price">
              <h4 class="c-white">AUD</h4>
              <h1>0</h1>
            </div>
            <div class="cost-header-details-item-price" v-for="(item, index) of getTotalCost" :key="index">
              <h4 class="c-white">{{ item.Currency }}</h4>
              <h1>{{ moneyFormat(item.Value) }}</h1>
            </div>
          </div>
        </div>
        <div class="cost-header-details-item">
          <span class="f-body-medium c-silver-2">Tax</span>
          <v-progress-circular color="primary" indeterminate v-if="loading" />
          <div v-else>
            <div v-if="getTotalTax.length == 0" class="cost-header-details-item-price">
              <h4 class="c-white">AUD</h4>
              <h1>0</h1>
            </div>
            <div class="cost-header-details-item-price" v-for="(item, index) of getTotalTax" :key="index">
              <h4 class="c-white">{{ item.Currency }}</h4>
              <h1>{{ moneyFormat(item.Value) }}</h1>
            </div>
          </div>
        </div>
        <div class="cost-header-details-item">
          <span class="f-body-medium c-silver-2">Average / Day</span>
          <v-progress-circular color="primary" indeterminate v-if="loading" />
          <div v-else>
            <div v-if="getAverageDayCost.length == 0" class="cost-header-details-item-price">
              <h4 class="c-white">AUD</h4>
              <h1>0</h1>
            </div>
            <div class="cost-header-details-item-price" v-for="(item, index) of getAverageDayCost" :key="index">
              <h4 class="c-white">{{ item.Currency }}</h4>
              <h1>{{ moneyFormat(item.Value) }}</h1>
            </div>
          </div>
        </div>
        <div class="cost-header-details-item">
          <span class="f-body-medium c-silver-2">Average / Week</span>
          <v-progress-circular color="primary" indeterminate v-if="loading" />
          <div v-else>
            <div v-if="getAverageWeekCost.length == 0" class="cost-header-details-item-price">
              <h4 class="c-white">AUD</h4>
              <h1>0</h1>
            </div>
            <div class="cost-header-details-item-price" v-for="(item, index) of getAverageWeekCost" :key="index">
              <h4 class="c-white">{{ item.Currency }}</h4>
              <h1>{{ moneyFormat(item.Value) }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-card :loading="loading" class="mt-4">
      <v-card-text>
        <div class="d-flex ga-6">
          <div>
            <v-menu v-model="viewMenu" offset-y transition="scale-transition">
              <template v-slot:activator="{ props }">
                <div outlined v-bind="props">
                  <div class="cost-filter-view">
                    <div>
                      <h6>View</h6>
                      <h5>{{ getBreakdown }}</h5>
                    </div>
                    <v-icon>mdi-menu-down</v-icon>
                  </div>
                </div>
              </template>
              <v-card>
                <v-card-text>
                  <v-list class="p-0">
                    <v-list-item @click="setBreakdown('service')">By Service</v-list-item>
                    <v-list-item @click="setBreakdown('resource')">By Resource</v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <div>
            <DatePicker @selectedDate="filterDataByDate" />
          </div>
        </div>

        <!-- <v-row no-gutters><LastTwelveMonth @filterDataByDate="filterDataByDate" /></v-row> -->
        <v-divider :thickness="2" class="cost-divider" />

        <ByServiceBreakdown :items="costDetails" :loading="loading" v-if="costBreakdown == 'service' && costDetails" />
        <ByResourceBreakdown :items="costDetails" :loading="loading" v-if="costBreakdown == 'resource' && costDetails" />
      </v-card-text>
    </v-card>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import ProjectTreeReport from "@/components/Report/ProjectTreeReport.vue"
  import Project from "shared-components/src/models/Project"
  import Timesheet from "shared-components/src/models/Timesheet"
  import { ActivityReport, TechnologyReport } from "shared-components/src/models/ProjectLeadReport"
  import TimesheetChartReport from "@/components/Report/TimesheetChartReport.vue"
  import LastTwelveMonth from "@/components/Range/LastTwelveMonth.vue"
  import { ProjectMessages } from "shared-components/src/definitions/constants"
  import ByServiceBreakdown from "./ByServiceBreakdown.vue"
  import ByResourceBreakdown from "./ByResourceBreakdown.vue"
  import { ClientCostReportItem, ReportApi } from "shared-components/src/services/openApi"
  import Utils from "shared-components/src/utils/Utils"
  import DatePicker from "@/components/DatePicker.vue"

  interface Month {
    startDate: Date
    endDate: Date
  }
  interface ProjectFilter {
    id: string
    epics: string[]
  }
  export default defineComponent({
    data() {
      return {
        projects: [] as Project[],
        filteredProjects: [] as Project[],
        filteredProjectsToShowInTree: [] as Project[],
        activityReport: [] as ActivityReport[],
        technologyReport: [] as TechnologyReport[],
        timesheets: [] as Timesheet[],
        filteredTimesheetsByDates: [] as Timesheet[],
        loading: false,
        selectedDates: {} as Month,
        selectedProjects: [] as ProjectFilter[],
        projectMessage: "",
        chartMessage: ProjectMessages.NoChartDetailInDates,
        costBreakdown: "service",
        costDetails: null as ClientCostReportItem[] | null,
        viewMenu: false,
      }
    },
    components: {
      ProjectTreeReport,
      TimesheetChartReport,
      LastTwelveMonth,
      ByServiceBreakdown,
      ByResourceBreakdown,
      DatePicker,
    },
    methods: {
      moneyFormat(value: any) {
        return Utils.moneyFormat(value)
      },
      setBreakdown(value: any) {
        this.costBreakdown = value
      },
      showNoData() {
        this.projectMessage = ""
        if (this.costDetails && this.costDetails.length === 0) {
          this.projectMessage = ProjectMessages.NoProjectsInDates
        }
      },
      async filterDataByDate(selectedFilters: any) {
        console.log(selectedFilters)
        this.loading = true
        if (selectedFilters.startDate) {
          var report = (await new ReportApi().getCostReport({ startDate: selectedFilters.startDate, endDate: selectedFilters.endDate })).data
          this.costDetails = report.Items ?? []
          this.selectedDates = selectedFilters
        }
        this.loading = false
        this.showNoData()
      },
    },
    computed: {
      getBreakdown() {
        switch (this.costBreakdown) {
          case "service":
            return "By Service"
          case "resource":
            return "By Resource"
          default:
            return "By Service"
        }
      },
      getTotalCost() {
        if (this.costDetails) {
          let sumValue = [] as { Value: number; Currency: string }[]
          this.costDetails.forEach((item) => {
            if (item.Fee && item.Quantity && item.Currency) {
              var sumObjectIndex = sumValue.findIndex((res) => res.Currency == item.Currency)
              if (sumObjectIndex != -1) {
                sumValue[sumObjectIndex].Value += item.Fee * item.Quantity
              } else {
                sumValue.push({ Value: item.Fee * item.Quantity, Currency: item.Currency })
              }
            }
          })
          return sumValue
        }
        return []
      },
      getTotalTax() {
        if (this.costDetails) {
          let sumValue = [] as { Value: number; Currency: string }[]
          this.costDetails.forEach((item) => {
            if (item.Fee && item.Quantity && item.Currency) {
              var sumObjectIndex = sumValue.findIndex((res) => res.Currency == item.Currency)
              if (sumObjectIndex != -1) {
                sumValue[sumObjectIndex].Value += ((item.Fee * item.Quantity) / 100) * (item.Tax ?? 0)
              } else {
                sumValue.push({ Value: ((item.Fee * item.Quantity) / 100) * (item.Tax ?? 0), Currency: item.Currency })
              }
            }
          })
          return sumValue
        }
        return []
      },
      getAverageDayCost() {
        if (this.getTotalCost.length > 0 && this.selectedDates) {
          const numberOfDays = Utils.getBusinessDays(this.selectedDates.startDate, this.selectedDates.endDate)

          return this.getTotalCost.map((item) => {
            return { Value: item.Value / numberOfDays, Currency: item.Currency }
          })
        }
        return []
      },
      getAverageWeekCost() {
        if (this.getTotalCost.length > 0 && this.selectedDates) {
          const numberOfWeeks = Utils.getWeeksBetween(this.selectedDates.startDate, this.selectedDates.endDate)
          return this.getTotalCost.map((item) => {
            return { Value: item.Value / numberOfWeeks, Currency: item.Currency }
          })
        }
        return []
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/style.scss";
  .transparent {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
  .emptyResult h1 {
    text-align: center;
  }

  .cost-filter-view {
    display: flex;
    gap: 6px;
    cursor: pointer;
    align-items: center;
  }
  .cost-divider {
    margin: 15px 0;
    color: $c_dim_gray;
  }

  .cost-header-container {
    .cost-header-title {
      padding: 0 24px;
    }

    .cost-header-details {
      display: flex;
      gap: 24px;
      .cost-header-details-item {
        display: flex;
        flex-direction: column;
        padding: 0 24px;
        .cost-header-details-item-price {
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
    }
  }

  :deep(.cost-table) {
    @extend .b-r-10;
    background-color: $c_jet_3;
    .v-data-table__thead {
      background-color: $c_onyx;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .cost-header-container {
      .cost-header-details {
        flex-direction: column;
        gap: 16px;
      }
    }
  }
</style>
