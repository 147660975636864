<template>
  <div class="cardTitle">
    <v-btn variant="text" icon @click="onPrevWeekClicked()">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <div class="week-title">
      {{ weekTitle() }}
    </div>
    <v-btn variant="text" icon @click="onNextWeekClicked()">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import Utils from "@/Helpers/Utils"
  export default defineComponent({
    props: ["offset"],
    data() {
      return {
        events: [] as any[],
        dayOffset: -1,
        newEventStartHours: [0, 0, 0, 0, 0, 0, 0],
        groupByDate: Utils.getWeekDaysArray(),
      }
    },
    async mounted() {
      await this.$emit("fetchData", {
        startDate: this.startDate,
        endDate: this.endDate,
      })
    },
    methods: {
      onNextWeekClicked() {
        this.events = []
        this.groupByDate = Utils.getWeekDaysArray()
        this.goNextWeek()
      },
      onPrevWeekClicked() {
        this.events = []
        this.groupByDate = Utils.getWeekDaysArray()
        this.goPrevWeek()
      },
      async goNextWeek() {
        this.dayOffset++
        this.newEventStartHours = [0, 0, 0, 0, 0, 0, 0]
        await this.$emit("fetchData", {
          startDate: this.startDate,
          endDate: this.endDate,
        })
      },
      async goPrevWeek() {
        this.dayOffset--
        this.newEventStartHours = [0, 0, 0, 0, 0, 0, 0]
        await this.$emit("fetchData", {
          startDate: this.startDate,
          endDate: this.endDate,
        })
      },
      weekTitle(): string {
        return `${Utils.toVsDateFormat(this.startDate).replace(/-/gi, "/")} - ${Utils.toVsDateFormat(this.endDate).replace(/-/gi, "/")}`
      },
    },
    computed: {
      startDate(): Date {
        var date = new Date()
        var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)
        const startDate = new Date(date.setDate(diff))
        startDate.setHours(0, 0, 0, 0)
        startDate.setDate(startDate.getDate() + this.dayOffset * 7)
        return startDate
      },
      endDate(): Date {
        const retVal = new Date(this.startDate.toString())
        retVal.setDate(retVal.getDate() + 6)
        return retVal
      },
    },
  })
</script>
<style scoped>
  .cardTitle {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: pre-wrap;
    height: auto;
    padding: 16px;
    justify-content: center;
    gap: 10px;
  }

  >>> .v-btn--icon.v-btn--density-default {
    width: calc(var(--v-btn-height)) !important;
    height: calc(var(--v-btn-height)) !important;
  }
  @media (max-width: 425px) {
    .week-title {
      font-size: 16px;
    }
  }
</style>
