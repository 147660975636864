<template>
  <div>
    <div v-if="editor">
      <v-tooltip top>
        <template v-slot:activator="{ attrs }">
          <v-btn
            v-bind="attrs"
            @click="editor.chain().focus().toggleBold().run()"
            :disabled="!editor.can().chain().focus().toggleBold().run() || disabled"
            :class="{
              'is-active': editor.isActive('bold'),
              'editor-btn': true,
            }">
            <span class="mdi mdi-format-bold"></span>
          </v-btn>
        </template>
        <span>Bold</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleItalic().run()"
            :disabled="!editor.can().chain().focus().toggleItalic().run() || disabled"
            :class="{
              'is-active': editor.isActive('italic'),
              'editor-btn': true,
            }">
            <span class="mdi mdi-format-italic"></span>
          </v-btn>
        </template>
        <span>Italic</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleStrike().run()"
            :disabled="!editor.can().chain().focus().toggleStrike().run() || disabled"
            :class="{
              'is-active': editor.isActive('strike'),
              'editor-btn': true,
            }">
            <span class="mdi mdi-format-strikethrough"></span>
          </v-btn>
        </template>
        <span>Strike</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleCode().run()"
            :disabled="!editor.can().chain().focus().toggleCode().run() || disabled"
            :class="{
              'is-active': editor.isActive('code'),
              'editor-btn': true,
            }">
            <span class="mdi mdi-code-not-equal-variant"></span>
          </v-btn>
        </template>
        <span>Code</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" :disabled="disabled" @click="editor.chain().focus().unsetAllMarks().run()" class="editor-btn">
            <span class="mdi mdi-format-clear"></span>
          </v-btn>
        </template>
        <span>Clear</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().setParagraph().run()"
            :class="{
              'is-active': editor.isActive('paragraph'),
              'editor-btn': true,
            }"
            :disabled="disabled">
            <span class="mdi mdi-format-paragraph"></span>
          </v-btn>
        </template>
        <span>Paragraph</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            :class="{
              'is-active': editor.isActive('heading', { level: 1 }),
              'editor-btn': true,
            }"
            :disabled="disabled">
            h1
          </v-btn>
        </template>
        <span>H1</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            :class="{
              'is-active': editor.isActive('heading', { level: 2 }),
              'editor-btn': true,
            }"
            :disabled="disabled">
            h2
          </v-btn>
        </template>
        <span>H2</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            :class="{
              'is-active': editor.isActive('heading', { level: 3 }),
              'editor-btn': true,
            }"
            :disabled="disabled">
            h3
          </v-btn>
        </template>
        <span>H3</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
            :class="{
              'is-active': editor.isActive('heading', { level: 4 }),
              'editor-btn': true,
            }"
            :disabled="disabled">
            h2
          </v-btn>
        </template>
        <span>h2</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
            :class="{
              'is-active': editor.isActive('heading', { level: 5 }),
              'editor-btn': true,
            }"
            :disabled="disabled">
            h5
          </v-btn>
        </template>
        <span>H5</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
            :class="{
              'is-active': editor.isActive('heading', { level: 6 }),
              'editor-btn': true,
            }"
            :disabled="disabled">
            h6
          </v-btn>
        </template>
        <span>H6</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{
              'is-active': editor.isActive('bulletList'),
              'editor-btn': true,
            }"
            :disabled="disabled">
            <span class="mdi mdi-format-list-bulleted"></span>
          </v-btn>
        </template>
        <span>Bullet List</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleOrderedList().run()"
            :class="{
              'is-active': editor.isActive('orderedList'),
              'editor-btn': true,
            }"
            :disabled="disabled">
            <span class="mdi mdi-format-list-numbered"></span>
          </v-btn>
        </template>
        <span>Ordered List</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleCodeBlock().run()"
            :class="{
              'is-active': editor.isActive('codeBlock'),
              'editor-btn': true,
            }"
            :disabled="disabled">
            <span class="mdi mdi-code-braces"></span>
          </v-btn>
        </template>
        <span>Code Block</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" :disabled="disabled" @click="editor.chain().focus().setHorizontalRule().run()" class="editor-btn">
            <span class="mdi mdi-minus"></span>
          </v-btn>
        </template>
        <span>Horizontal Line</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
            @click="setHyperLink"
            :class="{
              'is-active': editor.isActive('link'),
              'editor-btn': true,
            }">
            <span class="mdi mdi-link"></span>
          </v-btn>
        </template>
        <span>Set Hyper Link</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link') || disabled" class="editor-btn">
            <span class="mdi mdi-link-off"></span>
          </v-btn>
        </template>
        <span>Remove Hyper Link</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run() || disabled" class="editor-btn">
            <span class="mdi mdi-undo"></span>
          </v-btn>
        </template>
        <span>Undo</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run() || disabled" class="editor-btn">
            <span class="mdi mdi-redo"></span>
          </v-btn>
        </template>
        <span>Redo</span>
      </v-tooltip>
    </div>
    <EditorContent class="editor-content" :editor="editor" />
  </div>
</template>

<script lang="ts">
  import StarterKit from "@tiptap/starter-kit"
  import { Editor, EditorContent } from "@tiptap/vue-3"
  import Link from "@tiptap/extension-link"
  import { defineComponent } from "vue"

  export default defineComponent({
    components: {
      EditorContent,
    },
    props: {
      modelValue: {
        type: String,
        default: "",
      },
    },
    emits: ["update:modelValue"],
    data() {
      return {
        editor: null,
      }
    },
    watch: {
      modelValue(value: any) {
        const isSame = (this.editor as any).getHTML() === value
        if (isSame) {
          return
        }
        ;(this.editor as any).commands.setContent(value, false)
      },
    },
    mounted() {
      ;(this.editor as any) = new Editor({
        editable: true,
        extensions: [
          StarterKit,
          Link.configure({
            openOnClick: false,
          }),
        ],
        content: this.modelValue,
        onUpdate: () => {
          this.$emit("update:modelValue", (this.editor as any).getHTML())
        },
      })
    },
    beforeUnmount() {
      ;(this.editor as any).destroy()
    },
    methods: {
      setHyperLink() {
        const previousUrl = (this.editor as any).getAttributes("link").href
        const url = window.prompt("URL", previousUrl)

        // cancelled
        if (url === null) {
          return
        }

        // empty
        if (url === "") {
          ;(this.editor as any).chain().focus().extendMarkRange("link").unsetLink().run()

          return
        }

        // update link
        ;(this.editor as any).chain().focus().extendMarkRange("link").setLink({ href: url }).run()
      },
    },
  })
</script>
<style lang="scss">
  .editor-content {
    background-color: $c_mint_creme;
    border-radius: 5px;
  }
  .v-theme--dark h1 {
      color: black !important;
  }
  .ProseMirror {
    min-height: 100px;
    color: $c_black;
    padding: 15px;

    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h2,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: "JetBrainsMono", monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }
  }
</style>
