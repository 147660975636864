import { createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "inner-group-header depth-1"
}
const _hoisted_2 = {
  key: 1,
  class: "inner-group-header depth-2"
}
const _hoisted_3 = { colspan: 1 }
const _hoisted_4 = {
  key: 1,
  class: "inner-group-item depth-2"
}
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { colspan: 1 }
const _hoisted_7 = { colspan: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VBtn = _resolveComponent("VBtn")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createBlock(_component_v_data_table_server, {
    "hide-default-footer": "",
    "group-by": _ctx.groupBy,
    items: _ctx.items,
    headers: _ctx.headers,
    "item-value": "name",
    loading: _ctx.loading,
    "item-key": "Id",
    class: "cost-table"
  }, {
    "header.data-table-group": _withCtx(({}) => _cache[0] || (_cache[0] = [
      _createTextVNode("Project")
    ])),
    "group-header": _withCtx(({ item, columns, toggleGroup, isGroupOpen, index }) => [
      (_ctx.isFirstItemInDepth1(item, index))
        ? (_openBlock(), _createElementBlock("tr", _hoisted_1, _cache[1] || (_cache[1] = [
            _createElementVNode("td", { colspan: 2 }, [
              _createElementVNode("div", { class: "inner-group-header-title" }, [
                _createElementVNode("h5", null, "Team Member")
              ])
            ], -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.isFirstItemInDepth2(item, index))
        ? (_openBlock(), _createElementBlock("tr", _hoisted_2, _cache[2] || (_cache[2] = [
            _createElementVNode("td", { colspan: 2 }, [
              _createElementVNode("div", { class: "inner-group-header-title" }, [
                _createElementVNode("h5", null, "Service")
              ])
            ], -1)
          ])))
        : _createCommentVNode("", true),
      _createElementVNode("tr", null, [
        _createElementVNode("td", _hoisted_3, [
          (item.depth < 2)
            ? (_openBlock(), _createBlock(_component_VBtn, {
                key: 0,
                icon: isGroupOpen(item) ? '$expand' : '$next',
                size: "small",
                variant: "text",
                onClick: ($event: any) => (toggleGroup(item)),
                class: _normalizeClass(["inner-group-item", [item.depth == 1 ? 'depth-1' : '']])
              }, null, 8, ["icon", "onClick", "class"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4)),
          (item.depth < 2)
            ? (_openBlock(), _createElementBlock("span", {
                key: 2,
                class: _normalizeClass({ 'c-jordy-blue': item.depth == 0 })
              }, _toDisplayString(item.value), 3))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.getTeammemberName(item.value)), 1))
        ]),
        _createElementVNode("td", _hoisted_6, [
          _createElementVNode("div", null, _toDisplayString(_ctx.formatSumOfGroup(item)), 1)
        ]),
        _createElementVNode("td", _hoisted_7, [
          _createElementVNode("div", null, _toDisplayString(_ctx.formatSumOfTaxGroup(item)), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["group-by", "items", "headers", "loading"]))
}