<template>
  <div class="alert-container">
    <AlertItem type="Info" :items="infos" />
    <AlertItem type="Alert" :items="warnings" />
    <AlertItem type="Urgent" :items="urgents" />
  </div>
</template>
<script lang="ts">
  import { defineComponent, PropType } from "vue"
  import { TeammemberWithVmDetails } from "shared-components/src/services/openApi/api"
  import AlertItem from "./AlertItem.vue"

  export default defineComponent({
    components: { AlertItem },
    props: ["warnings", "infos", "urgents"],
    data() {
      return {}
    },
    computed: {},
  })
</script>
<style lang="scss" scoped>
  .alert-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
</style>
