<template>
  <v-row>
    <v-col cols="12" md="6" sm="12" xs="12" v-for="(item, index) in items" :key="item.IssueNo">
      <v-card class="dataBody">
        <v-card-text>
          <div>
            <div class="title-board">
              <v-row class="ma-0">
                <v-col md="4" class="pl-2 pr-1 pt-3 pb-3">
                  <span class="c-silver-2">Task</span>
                  {{ item.IssueNo }}
                </v-col>
                <v-col md="3" class="pl-1 pr-1 pt-3 pb-3">
                  {{ item.totalHours }}
                  <span class="c-silver-2">Hour(s)</span>
                </v-col>
                <v-col md="5" class="pl-1 pr-1 pt-3 pb-3">
                  {{ item.totalLines }}
                  <span class="c-silver-2">Lines</span>
                </v-col>
              </v-row>
            </div>
            <div class="descriptions">
              <v-row class="ma-0" :class="`${desIndex === item.details.length ? `` : `item`}`" v-for="(detail, desIndex) in item.details" :key="`d_${desIndex}`">
                <v-col cols="8" sm="4" class="pl-2 pr-1 pt-1 pb-1">
                  <span class="c-silver-2">{{ detail.Day }}</span>
                  {{ detail.Date }}
                </v-col>
                <v-col cols="3" class="pa-1">
                  {{ detail.Hours }}
                  <span class="c-silver-2">Hour(s)</span>
                </v-col>
                <v-col md="5" class="truncate-default pa-1 d-none d-sm-inline">
                  <span class="c-silver-2">{{ detail.Description }}</span>
                </v-col>
              </v-row>
            </div>
            <v-divider class="mb-4" />
            <div class="activities">
              <div v-for="(activity, actIndex) in item.activityItems" class="activity-box">
                <div
                  class="activity-item-box"
                  :title="`${activity.name} ${activity.totalHours}H`"
                  :key="`a_${actIndex}`"
                  :style="`${prepareChartStyle(actIndex, item.totalHours, activity.totalHours)}`"
                  @click="setActivity(activity.id)">
                  <div>
                    <span class="f-body-small activity-text-box">{{ activity.name }}</span>
                  </div>
                </div>
                <div class="technology-item-box">
                  <div
                    class="technology-item-box-item"
                    :title="`${technology.name} ${technology.totalHours}H`"
                    v-for="(technology, tchIndex) in activity.technologyItems"
                    :key="`t_${tchIndex}`"
                    :style="`${prepareChartStyle(tchIndex, activity.totalHours, technology.totalHours, true, activity.technologyItems.length)}`"
                    @click="setTechnology(technology.id)">
                    <div>
                      <span class="f-body-small activity-text-box">{{ technology.name }} ({{ technology.totalHours }}H)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="actions">
              <v-row class="ma-0">
                <v-col cols="12" sm="4" class="text-center">
                  <v-btn variant="outlined" class="f-body-medium action-btn" :disabled="true">Code Quality</v-btn>
                </v-col>
                <v-col cols="12" sm="4" class="text-center">
                  <v-btn variant="outlined" class="f-body-medium action-blue-btn" @click="OnReactClick(item)">+ Comment</v-btn>
                </v-col>
                <v-col cols="12" sm="4" class="text-center">
                  <v-btn variant="outlined" class="f-body-medium action-btn" :disabled="true" outlined>SME Review</v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

  <v-dialog persistent v-if="showReactionDialog" v-model="showReactionDialog" @keydown.esc="closeReactionDialog" max-width="440px">
    <reaction @cancel="closeReactionDialog" @saved="closeReactionDialog" @errorRaised="errorRaised" @successRaised="successRaised" :timesheetId="selectedTimesheetId"></reaction>
  </v-dialog>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import Timesheet from "shared-components/src/models/Timesheet"
  import ProfileAvatar from "@/components/Profile/ProfileAvatar.vue"
  import reaction from "./WeeklyPerformanceReportReaction.vue"
  import { REMOVE_SNAKBAR, SET_SNAKBAR_MODEL } from "../../store/types"
  import { mapMutations } from "vuex"
  import { UiTeammember } from "./Productivity.vue"
  import moment from "moment"
  import { useDisplay } from "vuetify/lib/framework.mjs"
  interface WeeklyPerformanceReportDictionaryItem {
    name: string
    id: string
    totalHours: number
    technologyItems: any[]
  }

  interface WeeklyPerformanceReportItem {
    IssueNo: string
    totalLines: number
    totalHours: number
    details: any[]
    timeSheetIds: string[]
    activityItems: WeeklyPerformanceReportDictionaryItem[]
  }

  export default defineComponent({
    components: {
      ProfileAvatar,
      reaction,
    },
    props: {
      teammember: {
        type: Object as () => UiTeammember,
        required: true,
      },
      timesheets: {
        type: Array as () => Timesheet[],
        required: true,
      },
    },
    data() {
      return {
        items: [] as WeeklyPerformanceReportItem[],
        showReactionDialog: false,
        selectedTimesheetId: "",
        weekDays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        activityColorPalette: ["#101F94", "#1327B9", "#172EDE", "#4357EF", "#6979F2", "#8E9AF5", "#B4BCF8", "#DADEFC", "#ECEEFD"],
        technologyColorPalette: ["#276568", "#328286", "#3D9FA4", "#4CB7BD", "#61BEC6", "#87CDD4", "#A5DADF", "#C3E7EA", "#E1F3F4"],
      }
    },
    mounted() {
      this.initItems()
    },
    methods: {
      ...mapMutations([SET_SNAKBAR_MODEL, REMOVE_SNAKBAR]),
      errorRaised(msg: string): void {
        this.SET_SNAKBAR_MODEL({
          body: msg,
          status: "error",
          button: "ok",
          show: true,
          handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
        })
      },
      successRaised(msg: string): void {
        this.SET_SNAKBAR_MODEL({
          body: msg,
          status: "success",
          button: "ok",
          show: true,
          handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
        })
      },
      closeReactionDialog(): void {
        this.showReactionDialog = false
      },
      OnReactClick(item: WeeklyPerformanceReportItem) {
        this.showReactionDialog = true
        this.selectedTimesheetId = item.timeSheetIds[0]
      },

      setActivity(id: string) {},
      setTechnology(id: string) {},
      initItems() {
        this.items = []
        if (this.timesheets) {
          this.timesheets.forEach((timesheet) => {
            let currentItem = this.items.find((x) => x.IssueNo == timesheet.IssueNo)

            if (currentItem == null) {
              // add item
              const newItem = {
                IssueNo: timesheet.IssueNo,
                details: [],
                activityItems: [],
                technologyItems: [],
                timeSheetIds: [],
                totalHours: 0,
                totalLines: 0,
              } as WeeklyPerformanceReportItem
              this.items.push(newItem)

              currentItem = newItem
            }
            const totalHour = timesheet.Effort + timesheet.Adjustment
            currentItem.details.push({
              Day: moment(timesheet.Date).format("ddd"),
              Date: moment(timesheet.Date).format("yyyy/MM/DD"),
              Hours: totalHour,
              Description: timesheet.Description,
            })
            currentItem.timeSheetIds.push(timesheet.id)
            currentItem.totalHours += totalHour

            let totalLines = 0
            const timesheetCommits = (timesheet as any).Commits as any[] | undefined
            if (timesheetCommits) {
              timesheetCommits.forEach((c) => {
                totalLines += c.linesChanged
              })
            }
            currentItem.totalLines = totalLines

            // set activity items
            const currentActivity = currentItem.activityItems.find((x) => x.id == timesheet.ActivityId)
            if (currentActivity == null) {
              const newActivity = {
                id: timesheet.ActivityId,
                name: timesheet.Activity,
                totalHours: totalHour,
                technologyItems: [
                  {
                    id: timesheet.TechnologyId,
                    name: timesheet.Technology,
                    totalHours: totalHour,
                  },
                ],
              } as WeeklyPerformanceReportDictionaryItem
              currentItem.activityItems.push(newActivity)
            } else {
              currentActivity.totalHours += totalHour
              const currentTechnology = currentActivity.technologyItems.find((x) => x.id == timesheet.TechnologyId)
              if (currentTechnology == null) {
                const newTechnology = {
                  id: timesheet.TechnologyId,
                  name: timesheet.Technology,
                  totalHours: totalHour,
                }
                currentActivity.technologyItems.push(newTechnology)
              } else {
                currentTechnology.totalHours += totalHour
              }
            }
          })
        }
      },
      prepareChartStyle(index: number, totalHour: number, hour: number, reverseColor: boolean = false, length: number | null) {
        const percentage = reverseColor ? (hour * 100) / totalHour : 100
        let color = ""
        if (reverseColor) {
          color = this.technologyColorPalette[index % 9]
        } else {
          color = this.activityColorPalette[index % 9]
        }
        let width = percentage
        let height = "55px"
        const { mobile } = useDisplay()
        if (mobile.value) {
          width = 100
          height = `${percentage}%`
        }
        return `width:${width}%;
          background-color:${color};
          height: ${height};
          border-radius: ${reverseColor ? (length != null && length == 1 ? (!mobile.value ? "0 0 10px 10px" : "0 10px 10px 0") : "") : ""};
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;`
      },
    },
    watch: {
      teammember: {
        immediate: true,
        handler(newval: any) {
          this.initItems()
        },
      },
    },
  })
</script>

<style scoped lang="scss">
  @import "node_modules/shared-components/assets/style/style.scss";
  .dataBody {
    background-color: $c_jet_3 !important;
    height: 100%;
    min-height: 400px;
    @extend .b-r-10;
  }

  .title-board {
    @extend .b-r-5;
    background-color: $c_jet;
  }
  .descriptions {
    height: 130px;
    overflow-y: auto;
    overflow-x: clip;
    margin-top: 8px;
  }

  .activities {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .activity-box {
      display: flex;
      flex-direction: column;
      .activity-item-box {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px 10px 0 0;
      }
      .technology-item-box {
        display: flex;

        .technology-item-box-item {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        :first-child {
          border-radius: 0 0 0 10px;
        }
        :last-child {
          border-radius: 0 0 10px 0;
        }
      }
    }
  }
  .actions {
    .action-btn {
      @extend .b-r-10;
      border: 1 solid $c_white;
      text-transform: none;
      width: 100%;
    }
    .action-blue-btn {
      @extend .b-r-10;
      border: 1 solid $c_jordy_blue;
      color: $c_jordy_blue;
      text-transform: none;
      width: 100%;
    }
  }
  .activity-text-box {
    background-color: $c_jet_3;
    padding: 4px 6px;
    @extend .b-r-3;
  }

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .activities {
      .activity-box {
        flex-direction: row;
        height: 90px;
        .activity-item-box {
          flex: none;
          padding: 8px 10px;
          height: 100% !important;
          width: 90px !important;
          border-radius: 10px 0 0 10px !important;
        }
        .technology-item-box {
          flex-direction: column;
          width: 100%;
          :first-child {
            border-radius: 0 10px 0 0;
          }
          :last-child {
            border-radius: 0 0 10px 0;
          }
        }
      }
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .activities {
      .activity-box {
        flex-direction: row;
        height: 90px;
        .activity-item-box {
          flex: none;
          padding: 8px 10px;
          height: 100% !important;
          width: 90px !important;
          border-radius: 10px 0 0 10px !important;
        }
        .technology-item-box {
          flex-direction: column;
          width: 100%;
          :first-child {
            border-radius: 0 10px 0 0;
          }
          :last-child {
            border-radius: 0 0 10px 0;
          }
        }
      }
    }
  }
</style>
