import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "pa-5 transparent",
    loading: _ctx.loading
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjects, (project) => {
        return (_openBlock(), _createBlock(_component_v_row, {
          key: project.id,
          align: "center",
          class: "flowContainer"
        }, {
          default: _withCtx(() => [
            (project.Epics && project.Epics.length > 0)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  md: "6",
                  sm: "6",
                  class: "project"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: _normalizeClass(["rectCard", project.isClicked ? 'selectedItem' : '']),
                      onClick: ($event: any) => (_ctx.getDataByProject(project.id))
                    }, _toDisplayString(project.Name) + " (" + _toDisplayString(_ctx.getProjectHours(project.id)) + ")", 11, _hoisted_1)
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true),
            _createVNode(_component_v_col, {
              dense: "",
              md: "6",
              sm: "6",
              class: _normalizeClass(project.Epics.length ? 'epic' : '')
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(project.Epics, (epic) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: epic.name,
                    class: "epicName"
                  }, [
                    _createVNode(_component_v_row, {
                      class: _normalizeClass(["rectCard", epic.isClicked ? 'selectedItem' : '']),
                      onClick: ($event: any) => (_ctx.getDataByEpic(project.id, epic.name))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h2", _hoisted_2, _toDisplayString(epic.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["class", "onClick"])
                  ]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["class"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["loading"]))
}