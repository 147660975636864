import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "alert-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertItem = _resolveComponent("AlertItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AlertItem, {
      type: "Info",
      items: _ctx.infos
    }, null, 8, ["items"]),
    _createVNode(_component_AlertItem, {
      type: "Alert",
      items: _ctx.warnings
    }, null, 8, ["items"]),
    _createVNode(_component_AlertItem, {
      type: "Urgent",
      items: _ctx.urgents
    }, null, 8, ["items"])
  ]))
}