<template>
  <v-data-table-server hide-default-footer :group-by="groupBy" :items="items" :headers="headers" item-value="name" :loading="loading" item-key="Id" class="cost-table">
    <template v-slot:header.data-table-group="{}">Project</template>
    <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen, index }">
      <tr v-if="isFirstItemInDepth1(item, index)" class="inner-group-header depth-1">
        <td :colspan="2">
          <div class="inner-group-header-title"><h5>Team Member</h5></div>
        </td>
      </tr>
      <tr v-if="isFirstItemInDepth2(item, index)" class="inner-group-header depth-2">
        <td :colspan="2">
          <div class="inner-group-header-title"><h5>Service</h5></div>
        </td>
      </tr>
      <tr>
        <td :colspan="1">
          <VBtn
            :icon="isGroupOpen(item) ? '$expand' : '$next'"
            size="small"
            variant="text"
            @click="toggleGroup(item)"
            class="inner-group-item"
            v-if="item.depth < 2"
            :class="[item.depth == 1 ? 'depth-1' : '']" />
          <div v-else class="inner-group-item depth-2"></div>
          <span v-if="item.depth < 2" :class="{ 'c-jordy-blue': item.depth == 0 }">{{ item.value }}</span>
          <span v-else>{{ getTeammemberName(item.value) }}</span>
        </td>
        <td :colspan="1">
          <div>
            {{ formatSumOfGroup(item) }}
          </div>
        </td>
        <td :colspan="1">
          <div>
            {{ formatSumOfTaxGroup(item) }}
          </div>
        </td>
      </tr>
    </template>
  </v-data-table-server>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import Utils from "shared-components/src/utils/Utils"

  export default defineComponent({
    props: ["items", "loading"],
    data() {
      return {
        groupBy: [
          {
            key: "ProjectName",
            order: "asc",
          },
          {
            key: "TeammemberName",
            order: "asc",
          },
          {
            key: "ServiceName",
            order: "asc",
          },
        ],
        headers: [
          { title: "Cost (Ex. Tax)", key: "cost", sortable: false },
          { title: "Tax", key: "tax", sortable: false },
        ],
        groupedItems: [] as any[],
      }
    },
    methods: {
      getTeammemberName(item: any) {
        const costItem = this.items.find((cost: any) => cost.TeammemberId == item)
        if (costItem) {
          return costItem.TeammemberName
        }
        return item
      },
      formatSumOfGroup(item: any) {
        const sumItems = this.getSumOfGroup(item)
        const result = [] as any[]
        sumItems.forEach((sum) => {
          var sumObjectIndex = result.findIndex((res) => res.Currency == sum.Currency)
          if (sumObjectIndex != -1) {
            result[sumObjectIndex].Value += sum.Value
          } else {
            result.push({ Value: sum.Value, Currency: sum.Currency })
          }
        })

        return result.map((item) => `${item.Value} ${item.Currency}`).join(" + ")
      },
      getSumOfGroup(item: any) {
        let sumValue = [] as { Value: number; Currency: string }[]
        if (item.items && item.items.length > 0) {
          item.items.forEach((cost: any) => {
            if (Object.keys(cost).includes("columns")) {
              sumValue.push({ Value: Number(Utils.toDigitsDecimals(cost.raw.Fee * cost.raw.Quantity, 2)), Currency: cost.raw.Currency })
            } else {
              sumValue = sumValue.concat(this.getSumOfGroup(cost))
            }
          })
        }
        return sumValue
      },
      formatSumOfTaxGroup(item: any) {
        const sumItems = this.getSumOfTaxGroup(item)
        const result = [] as any[]
        sumItems.forEach((sum) => {
          var sumObjectIndex = result.findIndex((res) => res.Currency == sum.Currency)
          if (sumObjectIndex != -1) {
            result[sumObjectIndex].Value += sum.Value
          } else {
            result.push({ Value: sum.Value, Currency: sum.Currency })
          }
        })

        return result.map((item) => `${Utils.moneyFormat(item.Value)} ${item.Currency}`).join(" + ")
      },
      getSumOfTaxGroup(item: any) {
        let sumValue = [] as { Value: number; Currency: string }[]
        if (item.items && item.items.length > 0) {
          item.items.forEach((cost: any) => {
            if (Object.keys(cost).includes("columns")) {
              sumValue.push({ Value: ((cost.raw.Fee * cost.raw.Quantity) / 100) * (cost.raw.Tax ?? 0), Currency: cost.raw.Currency })
            } else {
              sumValue = sumValue.concat(this.getSumOfTaxGroup(cost))
            }
          })
        }
        return sumValue
      },
      isFirstItemInDepth1(item: any, index: any) {
        item["index"] = index
        if (this.groupedItems.findIndex((it) => it.id == item.id) == -1) {
          this.groupedItems.push(item)
        }

        const parts = item.id.split("_")
        const parentId = parts.slice(0, 3).join("_")
        const groupedItems = this.groupedItems.filter((it) => it.id.includes(parentId)).sort((a, b) => a.index - b.index)

        if (groupedItems.length > 1) {
          return groupedItems[1].id == item.id && groupedItems[1].depth == 1
        }

        return false
      },
      isFirstItemInDepth2(item: any, index: any) {
        item["index"] = index
        if (this.groupedItems.findIndex((it) => it.id == item.id) == -1) {
          this.groupedItems.push(item)
        }

        const parts = item.id.split("_")
        const parentId = parts.slice(0, 5).join("_")
        const groupedItems = this.groupedItems.filter((it) => it.id.includes(parentId)).sort((a, b) => a.index - b.index)
        if (groupedItems.length > 1) {
          return groupedItems[1].id == item.id && groupedItems[1].depth == 2
        }

        return false
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/style.scss";

  .inner-group-item {
    &.depth-1 {
      margin-left: 75px;
    }
    &.depth-2 {
      display: inline-block;
      margin-left: 178px;
    }
  }
  .inner-group-header {
    td {
      padding: 0 !important;
    }
    .inner-group-header-title {
      background-color: $c_onyx;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 30px;
    }

    &.depth-1 {
      .inner-group-header-title {
        margin-left: 75px;
      }
    }
    &.depth-2 {
      .inner-group-header-title {
        margin-left: 150px;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .inner-group-item {
      &.depth-1 {
        margin-left: 10px;
      }
      &.depth-2 {
        margin-left: 48px;
      }
    }

    .inner-group-header {
      &.depth-1 {
        .inner-group-header-title {
          margin-left: 10px;
        }
      }
      &.depth-2 {
        .inner-group-header-title {
          margin-left: 20px;
        }
      }
    }
  }
</style>
