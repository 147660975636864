import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "item-details" }
const _hoisted_2 = { class: "item-header" }
const _hoisted_3 = { class: "item-sub-header" }
const _hoisted_4 = { class: "item-tm-detail-box" }
const _hoisted_5 = { class: "d-flex ga-2 align-center" }
const _hoisted_6 = { class: "item-tm-detail-name" }
const _hoisted_7 = {
  key: 0,
  class: "item-tm-detail-role"
}
const _hoisted_8 = { class: "f-body-small item-tm-detail-role-text" }
const _hoisted_9 = { class: "item-notif-container" }
const _hoisted_10 = { class: "item-code-details-container" }
const _hoisted_11 = { class: "item-code-details-box" }
const _hoisted_12 = { class: "body-medium-small c-white" }
const _hoisted_13 = { class: "d-flex ga-1 align-center" }
const _hoisted_14 = { class: "body-medium-small c-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_AlertBox = _resolveComponent("AlertBox")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-item-object", { 'active-tm': _ctx.selectedTeammemberId == _ctx.teammember.id }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Avatar, {
                Name: _ctx.teammember.fullName,
                Photo: _ctx.teammember.photoUrl,
                class: "item-tm-detail-avatar"
              }, null, 8, ["Name", "Photo"]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h6", null, _toDisplayString(_ctx.teammember.fullName), 1),
                (_ctx.teammember.jobRole && _ctx.teammember.jobRole != '')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _withDirectives((_openBlock(), _createBlock(_component_v_icon, { class: "item-tm-detail-icon" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.teammember.jobRoleIcon), 1)
                        ]),
                        _: 1
                      })), [
                        [_directive_tooltip, _ctx.teammember.jobRole]
                      ]),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.teammember.jobTitle && _ctx.teammember.jobTitle != "" ? _ctx.teammember.jobTitle : "-"), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "item-tm-detail-productivity" }, [
              _createElementVNode("span", { class: "f-body-extra-small" }, "Productivity Level"),
              _createElementVNode("h4", null, "A")
            ], -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_AlertBox, {
          warnings: _ctx.warnings,
          infos: _ctx.infos,
          urgents: _ctx.urgents
        }, null, 8, ["warnings", "infos", "urgents"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.tmHours), 1),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "body-medium-small c-silver-2" }, "Hour(s)", -1))
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.tmLines), 1),
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "body-medium-small c-silver-2" }, "Lines", -1))
        ])
      ]),
      _createVNode(_component_v_btn, {
        class: "select-tm-btn",
        "prepend-icon": "mdi-check",
        onClick: _ctx.selectTm
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.selectedTeammemberId == _ctx.teammember.id ? "Selected" : "Select Team Member"), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ], 2))
}