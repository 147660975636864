<template>
  <div class="po-item-object">
    <div class="item-details">
      <div class="item-po-detail-icon">
        <v-icon v-tooltip="position.JobRoleName">{{ position.JobRoleIcon }}</v-icon>
      </div>
      <div class="item-po-detail">
        <div class="item-po-detail-field">
          <span class="f-body-extra-small c-silver-2">Job Title</span>
          <span class="f-body-small">{{ position.JobTitles ? position.JobTitles : "-" }}</span>
        </div>
        <div class="item-po-detail-field">
          <span class="f-body-extra-small c-silver-2">Seats</span>
          <div class="item-po-detail-field-seets">
            <span class="f-body-small">{{ position.NoOfPositions - (position.SeatUnfilled ?? position.NoOfPositions) }} / {{ position.NoOfPositions }}</span>
            <div class="item-po-detail-field-seets-icon">
              <v-icon v-for="index in position.NoOfPositions" :key="index" :class="{ 'c-celadon': index <= position.NoOfPositions - position.SeatUnfilled }">
                mdi-sofa-single
              </v-icon>
            </div>
          </div>
        </div>

        <div class="item-po-detail-row-field">
          <div class="item-po-detail-field">
            <span class="f-body-extra-small c-silver-2">Start Date</span>
            <span class="f-body-small">{{ position.StartDate ? formatDate(position.StartDate) : "ASAP" }}</span>
          </div>
          <div v-if="position.ApplicationsStatuses?.length > 0" class="align-self-end">
            <span class="f-body-small c-jordy-blue">{{ position.ApplicationsStatuses?.length }} Applicants</span>
          </div>
        </div>
      </div>
    </div>

    <div>
      <v-btn @click="openPositionMenu()" variant="text" size="small" icon="mdi-cog" class="item-po-detail-setting" />
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from "vue"

  export default defineComponent({
    components: {},
    props: ["position"],
    data() {
      return {
        addLoading: false,
        showNewPosition: false,
        selectedPositionId: null as null | string,
        showPositionDetail: false,
        selectedCommitmentId: null as null | string,
        showCommitmentMenu: false,
        loading: false,
        showProjectModal: false,
        showActivityWatchModal: false,
        showWazuhModal: false,
        showVdSupportModal: false,
        selectedVmId: null as string | null,
        showEpicList: false,
      }
    },
    async mounted() {},
    methods: {
      openPositionMenu() {
        this.$emit("openPositionMenu")
      },
      formatDate(dateString: string | null | undefined) {
        if (!dateString) {
          return ""
        }
        return dateString.split("T")[0]
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/style.scss";
  .po-item-object {
    background-color: $c_jet_3;
    @extend .b-r-10;
    height: 100%;
    width: 100%;
    padding: 16px;
    border: solid 2px $c_dim_gray;
    display: flex;
    gap: 16px;
    justify-content: space-between;

    .item-po-detail-icon {
      background-color: $c_davys_grey;
      @extend .b-r-10;
      min-width: 24px !important;
      min-height: 24px !important;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      .v-icon {
        font-size: 16px;
        color: $c_white;
      }
    }
    .item-po-detail-setting {
      background-color: $c_powder_blue;
      @extend .b-r-10;
      width: 32px !important;
      height: 32px !important;
      color: $c_black;
    }

    .item-po-detail {
      line-height: normal;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 90%;
      .item-po-detail-row-field {
        display: flex;
        gap: 16px;
      }

      .item-po-detail-field {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .item-po-detail-field-seets {
          display: flex;
          gap: 8px;
          .item-po-detail-field-seets-icon {
            display: flex;
            gap: 2px;
            align-items: center;

            .v-icon {
              font-size: 12px;
            }
          }
        }
      }
    }

    .item-details {
      display: flex;
      gap: 8px;
      max-width: 90%;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .po-item-object {
      .item-details {
        display: flex;
        gap: 8px;
        max-width: 83%;
      }
    }
  }
</style>
