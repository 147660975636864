import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withKeys as _withKeys, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "title-board" }
const _hoisted_2 = { class: "descriptions" }
const _hoisted_3 = { class: "c-silver-2" }
const _hoisted_4 = { class: "c-silver-2" }
const _hoisted_5 = { class: "activities" }
const _hoisted_6 = { class: "activity-box" }
const _hoisted_7 = ["title", "onClick"]
const _hoisted_8 = { class: "f-body-small activity-text-box" }
const _hoisted_9 = { class: "technology-item-box" }
const _hoisted_10 = ["title", "onClick"]
const _hoisted_11 = { class: "f-body-small activity-text-box" }
const _hoisted_12 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_reaction = _resolveComponent("reaction")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createBlock(_component_v_col, {
            cols: "12",
            md: "6",
            sm: "12",
            xs: "12",
            key: item.IssueNo
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "dataBody" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_v_row, { class: "ma-0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                md: "4",
                                class: "pl-2 pr-1 pt-3 pb-3"
                              }, {
                                default: _withCtx(() => [
                                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "c-silver-2" }, "Task", -1)),
                                  _createTextVNode(" " + _toDisplayString(item.IssueNo), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_col, {
                                md: "3",
                                class: "pl-1 pr-1 pt-3 pb-3"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.totalHours) + " ", 1),
                                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "c-silver-2" }, "Hour(s)", -1))
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_col, {
                                md: "5",
                                class: "pl-1 pr-1 pt-3 pb-3"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.totalLines) + " ", 1),
                                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "c-silver-2" }, "Lines", -1))
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("div", _hoisted_2, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.details, (detail, desIndex) => {
                            return (_openBlock(), _createBlock(_component_v_row, {
                              class: _normalizeClass(["ma-0", `${desIndex === item.details.length ? `` : `item`}`]),
                              key: `d_${desIndex}`
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  cols: "8",
                                  sm: "4",
                                  class: "pl-2 pr-1 pt-1 pb-1"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_3, _toDisplayString(detail.Day), 1),
                                    _createTextVNode(" " + _toDisplayString(detail.Date), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_col, {
                                  cols: "3",
                                  class: "pa-1"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(detail.Hours) + " ", 1),
                                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "c-silver-2" }, "Hour(s)", -1))
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_col, {
                                  md: "5",
                                  class: "truncate-default pa-1 d-none d-sm-inline"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_4, _toDisplayString(detail.Description), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["class"]))
                          }), 128))
                        ]),
                        _createVNode(_component_v_divider, { class: "mb-4" }),
                        _createElementVNode("div", _hoisted_5, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.activityItems, (activity, actIndex) => {
                            return (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              (_openBlock(), _createElementBlock("div", {
                                class: "activity-item-box",
                                title: `${activity.name} ${activity.totalHours}H`,
                                key: `a_${actIndex}`,
                                style: _normalizeStyle(`${_ctx.prepareChartStyle(actIndex, item.totalHours, activity.totalHours)}`),
                                onClick: ($event: any) => (_ctx.setActivity(activity.id))
                              }, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("span", _hoisted_8, _toDisplayString(activity.name), 1)
                                ])
                              ], 12, _hoisted_7)),
                              _createElementVNode("div", _hoisted_9, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activity.technologyItems, (technology, tchIndex) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "technology-item-box-item",
                                    title: `${technology.name} ${technology.totalHours}H`,
                                    key: `t_${tchIndex}`,
                                    style: _normalizeStyle(`${_ctx.prepareChartStyle(tchIndex, activity.totalHours, technology.totalHours, true, activity.technologyItems.length)}`),
                                    onClick: ($event: any) => (_ctx.setTechnology(technology.id))
                                  }, [
                                    _createElementVNode("div", null, [
                                      _createElementVNode("span", _hoisted_11, _toDisplayString(technology.name) + " (" + _toDisplayString(technology.totalHours) + "H)", 1)
                                    ])
                                  ], 12, _hoisted_10))
                                }), 128))
                              ])
                            ]))
                          }), 256))
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_v_row, { class: "ma-0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                cols: "12",
                                sm: "4",
                                class: "text-center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    variant: "outlined",
                                    class: "f-body-medium action-btn",
                                    disabled: true
                                  }, {
                                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                                      _createTextVNode("Code Quality")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                sm: "4",
                                class: "text-center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    variant: "outlined",
                                    class: "f-body-medium action-blue-btn",
                                    onClick: ($event: any) => (_ctx.OnReactClick(item))
                                  }, {
                                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                                      _createTextVNode("+ Comment")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                sm: "4",
                                class: "text-center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    variant: "outlined",
                                    class: "f-body-medium action-btn",
                                    disabled: true,
                                    outlined: ""
                                  }, {
                                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                                      _createTextVNode("SME Review")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    (_ctx.showReactionDialog)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 0,
          persistent: "",
          modelValue: _ctx.showReactionDialog,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showReactionDialog) = $event)),
          onKeydown: _withKeys(_ctx.closeReactionDialog, ["esc"]),
          "max-width": "440px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_reaction, {
              onCancel: _ctx.closeReactionDialog,
              onSaved: _ctx.closeReactionDialog,
              onErrorRaised: _ctx.errorRaised,
              onSuccessRaised: _ctx.successRaised,
              timesheetId: _ctx.selectedTimesheetId
            }, null, 8, ["onCancel", "onSaved", "onErrorRaised", "onSuccessRaised", "timesheetId"])
          ]),
          _: 1
        }, 8, ["modelValue", "onKeydown"]))
      : _createCommentVNode("", true)
  ], 64))
}