<template>
  <v-form ref="frmMain" v-model="valid" lazy-validation>
    <v-card>
      <div class="card-title mb-4">
        <div class="card-title-left">
          {{ isNew == true ? "Add a new Position" : "Update Position Details" }}
        </div>
      </div>

      <v-card-text>
        <v-row class="align-center">
          <v-col cols="12" md="12">
            <AutoCompleteField dense label="Role" item-title="Name" item-value="id" :rules="[rules.required]" :items="getJobRoles" required v-model="model.JobRoleId" outlined />
          </v-col>
          <v-col cols="12" md="12">
            <MultiSelectCombobox
              dense
              clearable
              label="Job Titles"
              item-title="Name"
              item-value="id"
              closable-chips
              multiple
              :items="getJobTitle"
              :rules="[rules.required]"
              required
              v-model="model.JobTitles"
              :returnObject="false"
              outlined />
          </v-col>
          <v-col cols="12" md="4">
            <NumberField dense label="Number of Seats" :rules="[rules.required, rules.minNumber(1)]" v-model="model.NoOfPositions" outlined :min="1" />
          </v-col>
          <v-col cols="12" md="4">
            <DateInputField
              label="Start date"
              :rules="[asapCheckBox || rules.required]"
              first-day-of-week="1"
              v-model="model.StartDate"
              hide-actions
              placeholder="Start date"
              prepend-icon=""
              :disabled="asapCheckBox"
              :min="getTodayDate" />
          </v-col>
          <v-col cols="12" md="4">
            <v-checkbox hide-details label="ASAP" v-model="asapCheckBox" @change="asapChanged"></v-checkbox>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="6" md="6">
            <MultiSelectCombobox dense label="Skills" item-title="Text" item-value="id" :items="sortedSkills" closable-chips multiple v-model="model.Skills" outlined :returnObject="false" />
          </v-col>
          <v-col cols="6" md="6">
            <AutoCompleteField
              dense
              label="Country"
              item-title="text"
              item-value="value"
              :items="sortedLocations"
              :loading="isCountryLoading"
              :disabled="isCountryLoading"
              multiple
              chips
              closable-chips
              outlined
              v-model="model.CountryId" />
          </v-col>

          <v-col cols="12" md="12">
            <editor dense label="Job Description" outlined v-model="model.JobDescription" />
          </v-col>
        </v-row>
      </v-card-text>
      <div class="v-card-actions">
        <v-col cols="12" md="12">
          <v-btn class="right primary_btn" @click="save" :loading="loading" :disabled="loading">
            <v-icon>mdi-check</v-icon>
            Save
          </v-btn>
          <v-btn class="right mr-5 secondary_btn" @click="cancel" :disabled="loading">cancel</v-btn>
        </v-col>
      </div>
    </v-card>
  </v-form>
</template>

<script lang="ts">
  import { defineComponent, toRaw } from "vue"
  import rules from "shared-components/src/utils/validations"
  import Utils from "@/Helpers/Utils"
  import { CountryApi } from "shared-components/src/services/openApi/api"
  import Details from "shared-components/src/models/Details"
  import Editor from "../../../components/Editor.vue"
  var countryApi = new CountryApi()
  export default defineComponent({
    components: {
      Editor,
    },
    props: ["addLoading", "details", "position", "skills", "isNew"],
    data() {
      return {
        rules,
        valid: false,
        isCountryLoading: false,
        locations: [{ text: "", value: "" }],
        model: {
          JobRoleId: "",
          JobTitles: [] as Details[],
          JobDescription: "",
          Skills: [],
          NoOfPositions: 1,
          StartDate: null as Date | null,
          CountryId: [] as string[],
        },
        asapCheckBox: false,
        startDatePickerMenu: false,
        loading: false,
      }
    },
    async mounted() {
      await this.fetchCountries()
      if (this.position && this.position.Id) {
        this.model.JobRoleId = this.position.JobTitles == undefined || this.position.JobTitles.length == 0
          ? this.position.JobRoleId 
          : this.position.JobTitles[0]?.RoleId ?? "";
        this.model.StartDate = Utils.vsDateToDatetime(this.position.StartDate)
        this.model.NoOfPositions = this.position.NoOfPositions
        this.asapCheckBox = this.position.StartDate == null
        this.model.JobDescription = this.position.JobDescription
        this.model.Skills = this.position.Skills ? this.position.Skills.map((item: any) => item.id) : []
        this.model.CountryId = this.position.CountryId
        this.model.JobTitles = this.position.JobTitles ? this.position.JobTitles.map((item: any) => item.id) : []
      }
    },
    methods: {
      asapChanged() {
        this.model.StartDate = null
      },
      cancel() {
        this.$emit("cancel")
      },
      async save() {
        const isValid = (await (this.$refs.frmMain as any).validate()).valid
        if (isValid) {
          this.loading = true
          if (this.asapCheckBox) {
            this.model.StartDate = null
          }
          this.model.NoOfPositions = Number(this.model.NoOfPositions)
          this.$emit("success", this.model)
          this.loading = false;
        }
      },
      async fetchCountries() {
        this.isCountryLoading = true;
        var countryModels = await countryApi.clientGetCountries()
        this.locations = countryModels.data.map((item) => {
          return { text: item.Name ?? "", value: item.Id ?? "" }
        })
        this.isCountryLoading = false;
      },
    },
    computed: {
      sortedLocations() {
        const selectedCountries = this.model.CountryId || []

        return this.locations.slice().sort((a, b) => {
          const isSelectedA = selectedCountries.includes(a.value)
          const isSelectedB = selectedCountries.includes(b.value)
          if (isSelectedA && !isSelectedB) {
            return -1
          }
          if (!isSelectedA && isSelectedB) {
            return 1
          }
          return a.text.localeCompare(b.text)
        })
      },
      sortedSkills() {
        const selectedSkills = this.model.Skills || [];
        return (this.skills || []).slice().sort((a: any, b: any) => {
          const isSelectedA = (selectedSkills as any).includes(a.id)
          const isSelectedB = (selectedSkills as any).includes(b.id)
          if (isSelectedA && !isSelectedB) {
            return -1
          }
          if (!isSelectedA && isSelectedB) {
            return 1
          }
          return 0
        })
      },
      getTodayDate() {
        return new Date().toDateString()
      },
      getJobRoles() {
        return toRaw(this.details).filter((item: any) => item.Type == "JobRole")
      },
      getJobTitle() {
        const fileterItems = toRaw(this.details).filter((item: any) => item.Type == "JobTitle" && item.RoleId == this.model.JobRoleId);
        return fileterItems
      },
      formatedStartDate() {
        return this.model.StartDate ? Utils.toVsDateFormat(this.model.StartDate) : null
      },
    },
    watch: {
      addLoading(newVal) {
        this.loading = newVal
      },
    },
  })
</script>
