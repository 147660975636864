import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "po-item-object" }
const _hoisted_2 = { class: "item-details" }
const _hoisted_3 = { class: "item-po-detail-icon" }
const _hoisted_4 = { class: "item-po-detail" }
const _hoisted_5 = { class: "item-po-detail-field" }
const _hoisted_6 = { class: "f-body-small" }
const _hoisted_7 = { class: "item-po-detail-field" }
const _hoisted_8 = { class: "item-po-detail-field-seets" }
const _hoisted_9 = { class: "f-body-small" }
const _hoisted_10 = { class: "item-po-detail-field-seets-icon" }
const _hoisted_11 = { class: "item-po-detail-row-field" }
const _hoisted_12 = { class: "item-po-detail-field" }
const _hoisted_13 = { class: "f-body-small" }
const _hoisted_14 = {
  key: 0,
  class: "align-self-end"
}
const _hoisted_15 = { class: "f-body-small c-jordy-blue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives((_openBlock(), _createBlock(_component_v_icon, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.position.JobRoleIcon), 1)
          ]),
          _: 1
        })), [
          [_directive_tooltip, _ctx.position.JobRoleName]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "f-body-extra-small c-silver-2" }, "Job Title", -1)),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.position.JobTitles ? _ctx.position.JobTitles : "-"), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "f-body-extra-small c-silver-2" }, "Seats", -1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.position.NoOfPositions - (_ctx.position.SeatUnfilled ?? _ctx.position.NoOfPositions)) + " / " + _toDisplayString(_ctx.position.NoOfPositions), 1),
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.position.NoOfPositions, (index) => {
                return (_openBlock(), _createBlock(_component_v_icon, {
                  key: index,
                  class: _normalizeClass({ 'c-celadon': index <= _ctx.position.NoOfPositions - _ctx.position.SeatUnfilled })
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" mdi-sofa-single ")
                  ])),
                  _: 2
                }, 1032, ["class"]))
              }), 128))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "f-body-extra-small c-silver-2" }, "Start Date", -1)),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.position.StartDate ? _ctx.formatDate(_ctx.position.StartDate) : "ASAP"), 1)
          ]),
          (_ctx.position.ApplicationsStatuses?.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.position.ApplicationsStatuses?.length) + " Applicants", 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_v_btn, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openPositionMenu())),
        variant: "text",
        size: "small",
        icon: "mdi-cog",
        class: "item-po-detail-setting"
      })
    ])
  ]))
}