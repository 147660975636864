<template>
  <div class="month-container">
    <div v-for="date in dates" :key="`date-${date.name}`" class="dateItem" :class="date.isClicked ? 'selectedDate' : ''" @click="selectMonth(date)">
      <span>{{ date.name }}</span>
    </div>
  </div>
</template>

<script lang="ts">
  import Utils from "shared-components/src/utils/Utils"
  import { defineComponent } from "vue"

  interface Month {
    name: string
    startDate: Date
    endDate: Date
    isClicked: boolean
  }
  export default defineComponent({
    data() {
      return {
        dates: [] as Month[],
      }
    },
    async mounted() {
      this.lasttwelvemonth()

      await this.$emit("filterDataByDate", { startDate: this.dates[0].startDate, endDate: this.dates[11].endDate })
    },
    methods: {
      selectMonth(date: Month) {
        var dateItem = this.dates.find((c) => c.startDate === date.startDate)
        if (dateItem) {
          dateItem.isClicked = !dateItem.isClicked
        }
        var selectedDates = this.dates.filter((c) => c.isClicked)
        this.$emit("filterDataByDate", selectedDates)
      },
      subtractMonths(date: Date, months: number) {
        date.setMonth(date.getMonth() - months)
        return date
      },
      lasttwelvemonth() {
        var dates = [] as Date[]
        var d = new Date()
        var y = d.getFullYear()
        var m = d.getMonth() + 1
        const today = new Date()
        const lastTwelveMonths = [] as Month[]
        for (let i = 11; i >= 0; i--) {
          var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth() - i, 1)
          var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() - i + 1, 0)
          lastDayOfMonth = Utils.setDateTimes(lastDayOfMonth, 23, 59, 59, 999) as Date
          lastTwelveMonths.push({
            startDate: firstDayOfMonth,
            endDate: lastDayOfMonth,
            name: firstDayOfMonth.toLocaleString("en-us", { month: "short" }),
            isClicked: false,
          })
        }
        this.dates = lastTwelveMonths
      },
      padMonth(month: any) {
        if (month < 10) {
          return "0" + month
        } else {
          return month
        }
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/style.scss";
  .month-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .dateItem {
      text-align: center;
      @extend .b-r-10;
      border: 2px solid $c_dim_gray;
      cursor: pointer;
      padding: 8px 24px;
      background: $c_jet_3;
    }
    .selectedDate {
      background: $c_platinum;
      border: 2px solid $c_eerie_black;
      color: $c_eerie_black;
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .month-container {
      gap: 5px;
      justify-content: start;
      flex-wrap: wrap;
    }
  }
</style>
