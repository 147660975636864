import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "cost-header-container" }
const _hoisted_2 = { class: "cost-header-details" }
const _hoisted_3 = { class: "cost-header-details-item" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "cost-header-details-item-price"
}
const _hoisted_6 = { class: "c-white" }
const _hoisted_7 = { class: "cost-header-details-item" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  class: "cost-header-details-item-price"
}
const _hoisted_10 = { class: "c-white" }
const _hoisted_11 = { class: "cost-header-details-item" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 0,
  class: "cost-header-details-item-price"
}
const _hoisted_14 = { class: "c-white" }
const _hoisted_15 = { class: "cost-header-details-item" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = {
  key: 0,
  class: "cost-header-details-item-price"
}
const _hoisted_18 = { class: "c-white" }
const _hoisted_19 = { class: "d-flex ga-6" }
const _hoisted_20 = { class: "cost-filter-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_ByServiceBreakdown = _resolveComponent("ByServiceBreakdown")!
  const _component_ByResourceBreakdown = _resolveComponent("ByResourceBreakdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "cost-header-title" }, [
        _createElementVNode("h2", null, "Cost"),
        _createElementVNode("span", { class: "f-body-medium c-silver-2" }, "View and Manage your Project Costs")
      ], -1)),
      _createVNode(_component_v_divider, {
        thickness: 2,
        class: "cost-divider"
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "f-body-medium c-silver-2" }, "Total Cost (Ex. Tax)", -1)),
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                key: 0,
                color: "primary",
                indeterminate: ""
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.getTotalCost.length == 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[3] || (_cache[3] = [
                      _createElementVNode("h4", { class: "c-white" }, "AUD", -1),
                      _createElementVNode("h1", null, "0", -1)
                    ])))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTotalCost, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "cost-header-details-item-price",
                    key: index
                  }, [
                    _createElementVNode("h4", _hoisted_6, _toDisplayString(item.Currency), 1),
                    _createElementVNode("h1", null, _toDisplayString(_ctx.moneyFormat(item.Value)), 1)
                  ]))
                }), 128))
              ]))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "f-body-medium c-silver-2" }, "Tax", -1)),
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                key: 0,
                color: "primary",
                indeterminate: ""
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_ctx.getTotalTax.length == 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[5] || (_cache[5] = [
                      _createElementVNode("h4", { class: "c-white" }, "AUD", -1),
                      _createElementVNode("h1", null, "0", -1)
                    ])))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTotalTax, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "cost-header-details-item-price",
                    key: index
                  }, [
                    _createElementVNode("h4", _hoisted_10, _toDisplayString(item.Currency), 1),
                    _createElementVNode("h1", null, _toDisplayString(_ctx.moneyFormat(item.Value)), 1)
                  ]))
                }), 128))
              ]))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "f-body-medium c-silver-2" }, "Average / Day", -1)),
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                key: 0,
                color: "primary",
                indeterminate: ""
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (_ctx.getAverageDayCost.length == 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[7] || (_cache[7] = [
                      _createElementVNode("h4", { class: "c-white" }, "AUD", -1),
                      _createElementVNode("h1", null, "0", -1)
                    ])))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAverageDayCost, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "cost-header-details-item-price",
                    key: index
                  }, [
                    _createElementVNode("h4", _hoisted_14, _toDisplayString(item.Currency), 1),
                    _createElementVNode("h1", null, _toDisplayString(_ctx.moneyFormat(item.Value)), 1)
                  ]))
                }), 128))
              ]))
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[10] || (_cache[10] = _createElementVNode("span", { class: "f-body-medium c-silver-2" }, "Average / Week", -1)),
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                key: 0,
                color: "primary",
                indeterminate: ""
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                (_ctx.getAverageWeekCost.length == 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[9] || (_cache[9] = [
                      _createElementVNode("h4", { class: "c-white" }, "AUD", -1),
                      _createElementVNode("h1", null, "0", -1)
                    ])))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAverageWeekCost, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "cost-header-details-item-price",
                    key: index
                  }, [
                    _createElementVNode("h4", _hoisted_18, _toDisplayString(item.Currency), 1),
                    _createElementVNode("h1", null, _toDisplayString(_ctx.moneyFormat(item.Value)), 1)
                  ]))
                }), 128))
              ]))
        ])
      ])
    ]),
    _createVNode(_component_v_card, {
      loading: _ctx.loading,
      class: "mt-4"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", null, [
                _createVNode(_component_v_menu, {
                  modelValue: _ctx.viewMenu,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.viewMenu) = $event)),
                  "offset-y": "",
                  transition: "scale-transition"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createElementVNode("div", _mergeProps({ outlined: "" }, props), [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", null, [
                          _cache[12] || (_cache[12] = _createElementVNode("h6", null, "View", -1)),
                          _createElementVNode("h5", null, _toDisplayString(_ctx.getBreakdown), 1)
                        ]),
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode("mdi-menu-down")
                          ])),
                          _: 1
                        })
                      ])
                    ], 16)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list, { class: "p-0" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item, {
                                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setBreakdown('service')))
                                }, {
                                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                                    _createTextVNode("By Service")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_list_item, {
                                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setBreakdown('resource')))
                                }, {
                                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                                    _createTextVNode("By Resource")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_DatePicker, { onSelectedDate: _ctx.filterDataByDate }, null, 8, ["onSelectedDate"])
              ])
            ]),
            _createVNode(_component_v_divider, {
              thickness: 2,
              class: "cost-divider"
            }),
            (_ctx.costBreakdown == 'service' && _ctx.costDetails)
              ? (_openBlock(), _createBlock(_component_ByServiceBreakdown, {
                  key: 0,
                  items: _ctx.costDetails,
                  loading: _ctx.loading
                }, null, 8, ["items", "loading"]))
              : _createCommentVNode("", true),
            (_ctx.costBreakdown == 'resource' && _ctx.costDetails)
              ? (_openBlock(), _createBlock(_component_ByResourceBreakdown, {
                  key: 1,
                  items: _ctx.costDetails,
                  loading: _ctx.loading
                }, null, 8, ["items", "loading"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["loading"])
  ]))
}