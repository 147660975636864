import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cardTitle" }
const _hoisted_2 = { class: "week-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_btn, {
      variant: "text",
      icon: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPrevWeekClicked()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("mdi-chevron-left")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.weekTitle()), 1),
    _createVNode(_component_v_btn, {
      variant: "text",
      icon: "",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onNextWeekClicked()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, null, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("mdi-chevron-right")
          ])),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}