<template>
  <div class="my-4 search-box" v-click-outside="search">
    <TextAreaField
    class="search-box" bg-color="primary" density="comfortable" variant="outlined" hide-details="auto"
    placeholder="Search" hint="Describe your job role" label="Description" focused="focused" v-model="description" auto-grow @blur="search"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    jobDescription: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      description: this.jobDescription,
      focused: true as boolean,
    };
  },
  methods: {
    search() {
      this.focused = false;
      this.$emit("searchByDescription", this.description);
    }
  }
});
</script>
<style scoped lang="scss">
.search-box {
  display: flex;
  flex-direction: column;
  position: relative;

  .search-icon {
    position: absolute;
    top: 25px;
    right: -10px;
  }
}
</style>