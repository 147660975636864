<template>
  <v-tooltip location="bottom" content-class="alert-container" :eager="false">
    <template v-slot:activator="{ props }">
      <div :class="[type == 'Urgent' ? 'alert-urgent' : type == 'Alert' ? 'alert-warning' : 'alert-info']" v-bind="props" class="alert-item">
        <div class="d-flex ga-1 align-center">
          <v-icon class="alert-icon">
            {{ type == "Urgent" ? "mdi-close-octagon " : type == "Alert" ? "mdi-alert-rhombus" : "mdi-information" }}
          </v-icon>
          <span class="f-body-small alert-text">
            {{ type }}
          </span>
        </div>
        <span class="alert-count f-body-extra-small">{{ items.length }}</span>
      </div>
    </template>
    <div class="alert-content">
      <v-alert
        :text="item"
        v-for="(item, index) in items"
        :key="index"
        :type="type == 'Urgent' ? 'error' : type == 'Alert' ? 'warning' : 'info'"
        density="compact"
        class="alert-message" />
    </div>
  </v-tooltip>
</template>
<script lang="ts">
  import { defineComponent, PropType } from "vue"
  import { TeammemberWithVmDetailsCommitmentAdminApprovalEnum } from "shared-components/src/services/openApi/api"
  export default defineComponent({
    props: {
      tooltip: {
        type: String,
      },
      icon: {
        type: String,
      },
      type: {
        type: String as PropType<"Info" | "Alert" | "Urgent">,
      },
      items: {
        type: Array as PropType<String[]>,
      },
    },
    data() {
      return {
        alerts: [] as string[],
      }
    },
    computed: {},
    methods: {},
    async created() {},
    watch: {},
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/style.scss";

  :deep(.alert-container) {
    background: transparent !important;
    padding: 0 !important;
  }
  .alert-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .alert-item {
    display: flex;
    gap: 4px;
    padding: 4px;
    @extend .b-r-5;
    height: min-content;
    background-color: $c_jet_3;
    border: 1px solid $c_dim_gray;
    justify-content: space-between;
    align-items: center;
    width: 32%;
    @extend .b-r-3;

    .alert-icon {
      font-size: 14px;
    }
    .alert-count {
      border-radius: 20px;
      color: $c_black;
      width: 17px;
      height: 17px;
      text-align: center;
      line-height: 17px;
    }

    &.alert-info {
      color: $c_platinum;
      .alert-count {
        background-color: $c_platinum;
      }
    }
    &.alert-warning {
      color: $c_mindaro;
      .alert-count {
        background-color: $c_mindaro;
      }
    }
    &.alert-urgent {
      color: $c_light_red;
      .alert-count {
        background-color: $c_light_red;
      }
    }
  }
</style>
