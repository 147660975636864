import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "my-4 search-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TextAreaField, {
      class: "search-box",
      "bg-color": "primary",
      density: "comfortable",
      variant: "outlined",
      "hide-details": "auto",
      placeholder: "Search",
      hint: "Describe your job role",
      label: "Description",
      focused: "focused",
      modelValue: _ctx.description,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.description) = $event)),
      "auto-grow": "",
      onBlur: _ctx.search
    }, null, 8, ["modelValue", "onBlur"])
  ])), [
    [_directive_click_outside, _ctx.search]
  ])
}