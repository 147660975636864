import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, withKeys as _withKeys, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "setup-complete-container" }
const _hoisted_2 = {
  key: 1,
  class: "mt-3"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderItem = _resolveComponent("OrderItem")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_ServiceItem = _resolveComponent("ServiceItem")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_PriceCalculator = _resolveComponent("PriceCalculator")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_TimesheetProcessing = _resolveComponent("TimesheetProcessing")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.finalModel)
      ? (_openBlock(), _createBlock(_component_v_card, {
          key: 0,
          class: "b-eerie-black",
          loading: _ctx.loading,
          disabled: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Complete Setup", -1)),
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", null, [
                    _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Order Summary", -1)),
                    _createVNode(_component_v_card, { class: "mt-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_OrderItem, {
                              onModify: _ctx.modifyVd,
                              orderModel: _ctx.getVdOrderModel
                            }, null, 8, ["onModify", "orderModel"]),
                            (_ctx.finalModel.Addons?.TimesheetProcessingAddon?.Enabled)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "mt-3",
                                  onModify: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.modifyVd && _ctx.modifyVd(...args)))
                                }, [
                                  _createVNode(_component_v_divider),
                                  (_ctx.finalModel.Addons?.TimesheetProcessingAddon?.Enabled)
                                    ? (_openBlock(), _createBlock(_component_OrderItem, {
                                        key: 0,
                                        class: "mt-3",
                                        onModify: _ctx.showTimesheetProcessingModal,
                                        orderModel: _ctx.getTMOrderModel
                                      }, null, 8, ["onModify", "orderModel"]))
                                    : _createCommentVNode("", true)
                                ], 32))
                              : _createCommentVNode("", true),
                            (_ctx.finalModel.Addons?.CodeScanAddon?.Enabled)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                  _createVNode(_component_v_divider),
                                  (_ctx.finalModel.Addons?.CodeScanAddon?.Enabled)
                                    ? (_openBlock(), _createBlock(_component_OrderItem, {
                                        key: 0,
                                        class: "mt-3",
                                        orderModel: _ctx.getCSOrderModel
                                      }, null, 8, ["orderModel"]))
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_divider, { class: "mt-6 mb-6" }),
                    (!_ctx.finalModel.Addons?.TimesheetProcessingAddon?.Enabled || !_ctx.finalModel.Addons?.CodeScanAddon?.Enabled)
                      ? (_openBlock(), _createElementBlock("h2", _hoisted_3, "Add Services To Manage Your Team"))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_row, { class: "mt-2 mb-6" }, {
                      default: _withCtx(() => [
                        (!_ctx.finalModel.Addons?.TimesheetProcessingAddon?.Enabled)
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "12",
                              sm: "6",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ServiceItem, {
                                  onAdd: _ctx.showTimesheetProcessingModal,
                                  type: "TimesheetProcessing",
                                  fee: _ctx.getTimesheetFee,
                                  title: "Timesheet Processing",
                                  description: "Learn more about Timesheet Processing"
                                }, null, 8, ["onAdd", "fee"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (!_ctx.finalModel.Addons?.CodeScanAddon?.Enabled)
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 1,
                              cols: "12",
                              sm: "6",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ServiceItem, {
                                  type: "CodeScan",
                                  fee: _ctx.getCodeScanFee,
                                  title: "Code Scan",
                                  description: "Learn more about Code Scan",
                                  onAdd: _ctx.addCodeScanService
                                }, null, 8, ["fee", "onAdd"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card, { class: "border-white-1" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  cols: "12",
                                  md: "8"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_PriceCalculator, {
                                      feePerHour: _ctx.getTotalFeePerHour,
                                      isTotal: true,
                                      currency: _ctx.selectedCurrency
                                    }, null, 8, ["feePerHour", "currency"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, {
                                  cols: "12",
                                  md: "4",
                                  class: "d-flex align-end justify-end"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                      class: "primary_btn setup-complete-btn",
                                      onClick: _ctx.createVm
                                    }, {
                                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                                        _createTextVNode("Complete Setup")
                                      ])),
                                      _: 1
                                    }, 8, ["onClick"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading", "disabled"]))
      : _createCommentVNode("", true),
    (_ctx.showTimesheetProcessing)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 1,
          modelValue: _ctx.showTimesheetProcessing,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showTimesheetProcessing) = $event)),
          "max-width": "1000",
          onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.showTimesheetProcessing = false), ["esc"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TimesheetProcessing, {
              projectLeads: _ctx.projectLeads,
              portfolioManagers: _ctx.portfolioManagers,
              publicHolidayZones: _ctx.publicHolidayZones,
              onCancel: _ctx.closeTimesheetProcessingModal,
              onSave: _ctx.saveTimesheetProcessing,
              model: _ctx.finalModel
            }, null, 8, ["projectLeads", "portfolioManagers", "publicHolidayZones", "onCancel", "onSave", "model"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}