import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex ga-1 align-center" }
const _hoisted_2 = { class: "f-body-small alert-text" }
const _hoisted_3 = { class: "alert-count f-body-extra-small" }
const _hoisted_4 = { class: "alert-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, {
    location: "bottom",
    "content-class": "alert-container",
    eager: false
  }, {
    activator: _withCtx(({ props }) => [
      _createElementVNode("div", _mergeProps({
        class: [_ctx.type == 'Urgent' ? 'alert-urgent' : _ctx.type == 'Alert' ? 'alert-warning' : 'alert-info']
      }, props, { class: "alert-item" }), [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_icon, { class: "alert-icon" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.type == "Urgent" ? "mdi-close-octagon " : _ctx.type == "Alert" ? "mdi-alert-rhombus" : "mdi-information"), 1)
            ]),
            _: 1
          }),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.type), 1)
        ]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.items.length), 1)
      ], 16)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createBlock(_component_v_alert, {
            text: item,
            key: index,
            type: _ctx.type == 'Urgent' ? 'error' : _ctx.type == 'Alert' ? 'warning' : 'info',
            density: "compact",
            class: "alert-message"
          }, null, 8, ["text", "type"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}