<template>
  <v-dialog v-model="showCommitmentMenuDialog" persistent max-width="1000px">
    <v-card :loading="dialogLoading" :disabled="dialogLoading">
      <div class="card-title" v-if="selectedCommitment?.TeammemberId">
        <div class="card-title-left">
          <div>
            <Avatar :Name="selectedCommitment?.TeammemberName" :Photo="selectedCommitment?.PhotoUrl" />
            <span class="tm-name">
              {{ selectedCommitment?.TeammemberName }}
            </span>
          </div>
        </div>
        <div class="card-title-right">
          <v-icon @click="closeDialog">mdi-close</v-icon>
        </div>
      </div>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="6">
            <v-row no-gutters>
              <v-cols cols="12" class="w-100">
                <v-skeleton-loader class="project-skeleton" type="button@2, button@2, button@2" v-if="dialogLoading" />
                <v-card variant="tonal" class="b-r-5 action-boxs" v-if="!dialogLoading">
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-row class="mb-1">
                          <v-col cols="12" class="text-center">Actions</v-col>
                        </v-row>
                        <div class="menu-action-btns" v-if="selectedCommitment && selectedCommitment.AllowedActions && selectedCommitment.AllowedActions?.length > 0">
                          <v-btn v-if="checkActionPermission(actionLists.CustomerExtendContract)" class="menu-btn" prepend-icon="" @click="showExtendContractAction()">
                            {{ getAllowedActionName(actionLists.CustomerExtendContract) }}
                          </v-btn>
                          <v-btn v-if="checkActionPermission(actionLists.CustomerTerminateContract)" class="menu-btn" prepend-icon="" @click="showTerminateContractAction()">
                            {{ getAllowedActionName(actionLists.CustomerTerminateContract) }}
                          </v-btn>
                          <v-btn v-if="checkActionPermission(actionLists.CustomerAddVirtualDesktop)" class="menu-btn" prepend-icon="" @click="showAddVirtualDeskopAction()">
                            {{ getAllowedActionName(actionLists.CustomerAddVirtualDesktop) }}
                          </v-btn>
                          <v-btn v-if="checkActionPermission(actionLists.CustomerVdSupport)" class="menu-btn" prepend-icon="" @click="openVdSupport()">
                            {{ getAllowedActionName(actionLists.CustomerVdSupport) }}
                          </v-btn>
                          <v-btn v-if="checkActionPermission(actionLists.CustomerModifyVirtualDesktop)" @click="showModifyVirtualDeskopAction()" class="menu-btn">
                            {{ getAllowedActionName(actionLists.CustomerModifyVirtualDesktop) }}
                          </v-btn>
                          <v-btn @click="showTerminateVirtualDeskopAction()" v-if="checkActionPermission(actionLists.CustomerTerminateVirtualDesktop)" class="menu-btn">
                            {{ getAllowedActionName(actionLists.CustomerTerminateVirtualDesktop) }}
                          </v-btn>
                          <v-btn @click="showCodeIntegrationAction()" v-if="checkActionPermission(actionLists.CustomerCodeIntegration)" class="menu-btn">
                            {{ getAllowedActionName(actionLists.CustomerCodeIntegration) }}
                          </v-btn>
                        </div>
                        <div
                          v-else-if="
                            selectedCommitment && (!selectedCommitment.AllowedActions || selectedCommitment.AllowedActions?.length == 0) && selectedCommitment.CommitmentActions
                          "
                          class="no-action-container">
                          <span class="note">
                            You have already submitted a request to make the following changes to this Commitment for
                            {{ formatDate(selectedCommitment?.CommitmentActions?.EffectiveDate) }}, if you wish to Cancel/Update them, please contact an Administrator.
                          </span>
                          <div v-for="(action, index) in selectedCommitment?.CommitmentActions?.ActionItems" :key="index">
                            {{ action.DisplayName }}
                            <v-icon size="small" icon="mdi-information" v-tooltip:top="getActionDetail(action)"></v-icon>
                          </div>
                        </div>
                        <div v-else class="no-action-container">
                          <span class="note">No actions available for this commitment. Please contact Administrator.</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-cols>
              <v-cols cols="12" class="mt-2 w-100">
                <v-skeleton-loader class="project-skeleton" type="button@2, button@2, button@2" v-if="dialogLoading" />
                <v-card variant="tonal" class="b-r-5 action-boxs" v-if="!dialogLoading">
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-row class="mb-1">
                          <v-col cols="12" class="text-center">Insights</v-col>
                        </v-row>
                        <div class="menu-action-btns">
                          <v-btn class="menu-btn">Current Status</v-btn>
                          <v-btn class="menu-btn">Timesheet Flag Yesterday</v-btn>
                          <v-btn class="menu-btn">Timesheet Flag Last Week</v-btn>
                          <v-btn class="menu-btn">Contract Ending Soon</v-btn>
                          <v-btn class="menu-btn">Add-On Upsell</v-btn>
                          <v-btn class="menu-btn">Notification</v-btn>
                          <v-btn class="menu-btn">Attention</v-btn>
                          <v-btn class="menu-btn">Issues</v-btn>
                          <v-btn class="menu-btn" v-if="selectedCommitment?.VmId" @click="openActivityReport()">Activity Report</v-btn>
                          <v-btn class="menu-btn" v-if="selectedCommitment?.VmId" @click="openSecurityReport()">Security Report</v-btn>
                          <v-btn class="menu-btn" v-if="selectedCommitment?.VmId" @click="openCodeIssuesReport()">Code Issues Report</v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-cols>
            </v-row>
          </v-col>
          <v-col cols="6" class="p-0">
            <v-skeleton-loader class="mx-auto border" type="article" v-if="dialogLoading" />
            <div v-else>
              <div class="help-section">
                <div class="help-item">
                  <div class="help-color b-emerald"></div>
                  <span>Active</span>
                </div>
                <div class="help-item">
                  <div class="help-color b-orange"></div>
                  <span>Upcoming</span>
                </div>
                <div class="help-item">
                  <div class="help-color b-battleship-grey1"></div>
                  <span>Expired</span>
                </div>
                <div class="help-item">
                  <v-icon>mdi-star</v-icon>
                  <span>Action based on</span>
                </div>
              </div>
              <div class="timeline-container">
                <CommitmentMenuTimeline :commitment="selectedCommitment" v-if="selectedCommitment" />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-dialog v-model="displayActivityReport" v-if="displayActivityReport && selectedCommitment?.VmId" max-width="90%" @keydown.esc="closeActivityWatchReport">
          <v-card>
            <v-card-text>
              <ActivityWatchReport :vmId="selectedCommitment?.VmId" @Close="closeActivityWatchReport" :isClient="true"></ActivityWatchReport>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="displayCodeIssuesReport" v-if="displayCodeIssuesReport && selectedCommitment?.VmId" max-width="90%" @keydown.esc="closeCodeIssuesReport">
          <v-card>
            <v-card-text>
              <SonarQubeReport :vmId="selectedCommitment?.VmId" @Close="closeCodeIssuesReport" :isClient="true"></SonarQubeReport>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="displaySecurityReport" v-if="displaySecurityReport && selectedCommitment?.VmId" max-width="900" persistent @keydown.esc="closeSecurityReport">
          <v-card>
            <v-card-text>
              <SecurityReport :vmId="selectedCommitment?.VmId" @close="closeSecurityReport" :isClient="true"></SecurityReport>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="displayVdSupport" max-width="900" persistent @keydown.esc="closeVdSupportPopup">
          <VdSupport :isClient="true" @cancel="closeVdSupportPopup" @sendRequest="createVdSupport"></VdSupport>
        </v-dialog>

        <CommitmentActionModal
          v-if="showActionModal"
          :commitmentAction="selectedCommitmentAction"
          :commitment="selectedCommitment"
          @OnClose="closeCommitmentActionModal"
          @OnSuccess="commitmentActionApplied" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import {
    ClientCommitmentApi,
    ClientGetCommitmentByIdResponseModel,
    CommitmentActionItem,
    CommitmentActionItemTypeEnum,
    CommitmentActionWithAltItem,
    CommitmentActionWithAltItemTypeEnum,
    TeammemberWithVmDetails,
  } from "shared-components/src/services/openApi/api"
  import ProjectSearchWizard from "../AddTeammember/ProjectSearchWizard.vue"
  import AppHelper from "shared-components/src/utils/AppHelper"
  import Utils from "shared-components/src/utils/Utils"
  import ActivityWatchReport from "shared-components/src/components/Reports/ActivityWatch/ActivityWatchReport.vue"
  import SecurityReport from "shared-components/src/components/Reports/Wazuh/Dashboard.vue"
  import VdSupport from "shared-components/src/components/Vm/VdSupport.vue"
  import CommitmentActionModal from "shared-components/src/components/Commitment/Actions/CommitmentActionModal.vue"
  import { ClientVMApi, ClientVmSupportRequest } from "shared-components/src/services/openApi/api"
  import store from "@/store"
  import Avatar from "@/components/Avatar.vue"
  import moment from "moment"
  import CommitmentMenuTimeline from "./CommitmentMenuTimeline.vue"
  import SonarQubeReport from "shared-components/src/components/Reports/SonarQube/SonarQubeReport.vue"

  export default defineComponent({
    components: {
      ProjectSearchWizard,
      ActivityWatchReport,
      VdSupport,
      SecurityReport,
      Avatar,
      CommitmentActionModal,
      CommitmentMenuTimeline,
      SonarQubeReport,
    },
    props: ["details", "project", "projectId", "teamMember", "projectList"],
    data() {
      return {
        showActionModal: false,
        showVdSupportModal: false,
        displayVdSupport: false,
        displaySecurityReport: false,
        displayActivityReport: false,
        displayCodeIssuesReport: false,
        dialogLoading: false,
        showSearchTeammember: false,
        addLoading: false,
        showUpdatePosition: false,
        showCommitmentMenuDialog: true,
        selectedCommitment: null as ClientGetCommitmentByIdResponseModel | null,
        selectedCommitmentAction: null as null | CommitmentActionWithAltItem,
        actionLists: CommitmentActionItemTypeEnum,
      }
    },
    async created() {
      await this.loadCommitment()
    },
    mounted() {},
    methods: {
      async loadCommitment() {
        const commitmentId = this.getCommitmentId()
        if (commitmentId) {
          try {
            this.dialogLoading = true
            this.selectedCommitment = (await new ClientCommitmentApi().clientGetCommitment(commitmentId)).data
            this.dialogLoading = false
          } catch {
            this.closeDialog()
          }
        }
      },
      getAllowedActionName(action: CommitmentActionItemTypeEnum) {
        return this.selectedCommitment?.AllowedActions?.find((item) => item.Type == action)?.DisplayName
      },
      getActionDetail(action: CommitmentActionItem) {
        switch (action.Type) {
          case CommitmentActionItemTypeEnum.CustomerAddVirtualDesktop:
            return `Cpu: ${action.Details?.VirtualDesktop?.Cpu}, Memory: ${action.Details?.VirtualDesktop?.Ram}, Disk: ${action.Details?.VirtualDesktop?.Disk}`
          case CommitmentActionItemTypeEnum.CustomerModifyVirtualDesktop:
            return `Cpu: ${action.Details?.VirtualDesktop?.Cpu}, Memory: ${action.Details?.VirtualDesktop?.Ram}, Disk: ${action.Details?.VirtualDesktop?.Disk}`
          case CommitmentActionItemTypeEnum.CustomerExtendContract:
            return `Extend To Date: ${moment(action.Details?.ExtendContractDate).format("YYYY-MM-DD")}`
          case CommitmentActionItemTypeEnum.CustomerTerminateContract:
            return `Transfer Virtual Desktop: ${action.Details?.TerminateContract?.TransferVirtualDesktop}`
        }
      },
      checkActionPermission(action: CommitmentActionItemTypeEnum) {
        return this.selectedCommitment?.AllowedActions?.map((item) => item.Type).includes(action)
      },
      truncate(item: any) {
        if (item && item.length > 30) {
          return item.substring(0, 30) + "..."
        } else {
          return item
        }
      },
      formatDate(date: any) {
        date = new Date(date)
        if (date) {
          return Utils.toVsDateFormat(date)
        }
      },
      getCommitmentId() {
        return this.teamMember?.CommitmentId ?? this.$route.query.commitmentId
      },
      showAddVirtualDeskopAction() {
        const action = this.selectedCommitment?.AllowedActions?.find(
          (item: CommitmentActionWithAltItem) => item.Type == CommitmentActionWithAltItemTypeEnum.CustomerAddVirtualDesktop
        )
        if (action) {
          this.selectedCommitmentAction = action
          this.showActionModal = true
        }
      },
      showModifyVirtualDeskopAction() {
        const action = this.selectedCommitment?.AllowedActions?.find(
          (item: CommitmentActionWithAltItem) => item.Type == CommitmentActionWithAltItemTypeEnum.CustomerModifyVirtualDesktop
        )
        if (action) {
          this.selectedCommitmentAction = action
          ;(this.selectedCommitmentAction as any).VirtualDesktopSpec = this.selectedCommitment?.VirtualDesktopSpec

          this.showActionModal = true
        }
      },
      showExtendContractAction() {
        const action = this.selectedCommitment?.AllowedActions?.find((item: CommitmentActionWithAltItem) => item.Type == CommitmentActionWithAltItemTypeEnum.CustomerExtendContract)
        if (action) {
          this.selectedCommitmentAction = action
          this.showActionModal = true
        }
      },
      showTerminateContractAction() {
        const action = this.selectedCommitment?.AllowedActions?.find(
          (item: CommitmentActionWithAltItem) => item.Type == CommitmentActionWithAltItemTypeEnum.CustomerTerminateContract
        )
        if (action) {
          this.selectedCommitmentAction = action
          ;(this.selectedCommitmentAction as any).HasVm = false
          if (this.selectedCommitment?.VmId) {
            ;(this.selectedCommitmentAction as any).HasVm = true
            ;(this.selectedCommitmentAction as any).RelatedCommitments = this.getTeammemberCommitmentsinCustomer
          }
          this.showActionModal = true
        }
      },
      showTerminateVirtualDeskopAction() {
        const action = this.selectedCommitment?.AllowedActions?.find(
          (item: CommitmentActionWithAltItem) => item.Type == CommitmentActionWithAltItemTypeEnum.CustomerTerminateVirtualDesktop
        )
        if (action) {
          this.selectedCommitmentAction = action
          this.showActionModal = true
        }
      },
      showCodeIntegrationAction() {
        const action = this.selectedCommitment?.AllowedActions?.find(
          (item: CommitmentActionWithAltItem) => item.Type == CommitmentActionWithAltItemTypeEnum.CustomerCodeIntegration
        )
        if (action) {
          this.selectedCommitmentAction = action
          this.showActionModal = true
        }
      },
      closeCommitmentActionModal() {
        this.selectedCommitmentAction = null
        this.showActionModal = false
      },
      async commitmentActionApplied() {
        this.selectedCommitment = null
        this.closeCommitmentActionModal()
        await this.loadCommitment()
        this.$emit("OnActionApplied", this.getCommitmentId())
      },
      closeVdSupportPopup() {
        this.displayVdSupport = false
      },
      closeSecurityReport() {
        this.displaySecurityReport = false
      },
      closeActivityWatchReport() {
        this.displayActivityReport = false
      },
      closeCodeIssuesReport() {
        this.displayCodeIssuesReport = false
      },
      openVdSupport() {
        if (!this.selectedCommitment?.VmId) {
          return
        } else {
          this.displayVdSupport = true
        }
      },
      openSecurityReport() {
        if (this.selectedCommitment?.VmId) {
          this.displaySecurityReport = true
        }
      },
      openActivityReport() {
        if (this.selectedCommitment?.VmId) {
          this.displayActivityReport = true
        }
      },
      openCodeIssuesReport() {
        if (this.selectedCommitment?.VmId) {
          this.displayCodeIssuesReport = true
        }
      },
      closeDialog() {
        this.$emit("OnClose")
      },
      GetColour(name: any) {
        return AppHelper.GetColour(name)
      },
      GetInitials(name: any) {
        return AppHelper.GetInitials(name)
      },
      async createVdSupport(event: any) {
        if (this.selectedCommitment?.VmId) {
          try {
            const result = (
              await new ClientVMApi().clientVmSupport(this.selectedCommitment?.VmId, {
                Message: event.Message,
                Title: event.Title,
              } as ClientVmSupportRequest)
            ).data
            this.displayVdSupport = false
            store.dispatch("showSuccessMessage", "Vd support request submitted successfully")
          } catch {
            store.dispatch("showErrorMessage", "An error occurred while submitting the Vd support request")
          } finally {
          }
        }
      },
    },
    computed: {
      getTeammemberCommitmentsinCustomer() {
        let relatedCommitments = [] as { Id: string; Name: string }[]
        this.projectList.forEach((project: any) => {
          const projectRelatedCommitments = project.Commitments.filter(
            (item: TeammemberWithVmDetails) =>
              item.Id == this.selectedCommitment?.TeammemberId && item.CommitmentId != this.selectedCommitment?.Id && !item.IsCommitmentExpired && !item.HaveUpcomingCommitment
          ).map((item: TeammemberWithVmDetails) => {
            return {
              Id: item.CommitmentId,
              Name: `${item.Name}-${project.Name}`,
            }
          })
          relatedCommitments = [...relatedCommitments, ...projectRelatedCommitments]
        })
        return relatedCommitments
      },
      avatarColor() {
        const color = this.selectedCommitment?.PhotoUrl ? null : this.GetColour(this.selectedCommitment?.Name)
        return typeof color === "string" ? color : undefined
      },
      getEpics() {
        return this.project?.Epics ? this.project.Epics.join(", ") : "No Epics"
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/font.scss";

  ::v-deep .project-skeleton {
    .v-skeleton-loader__bone {
      flex: 40% !important;
    }
    .v-skeleton-loader__button {
      max-width: 50% !important;
    }
    .v-skeleton-loader__button + .v-skeleton-loader__bone {
      flex: 40% !important;
      margin-inline-start: 16px !important;
    }
  }

  .no-action-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 5px;
    align-items: center;
    .note {
      color: $c_orange;
      @extend .f-body-2;
      text-align: center;
    }
  }
  .info-boxs {
    height: 100%;
    min-height: 250px;
    padding: 5px;
  }
  .action-boxs {
    min-height: 380px;
  }
  .menu-action-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .menu-btn {
      font-size: 12px;
      flex: 45%;
    }
  }

  .tm-name {
    padding-left: 5px;
  }
  .timeline-container {
    max-height: 740px;
    overflow-y: auto;
    padding: 15px;
    height: 100%;
  }
  .help-section {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    justify-content: center;
    .help-item {
      display: flex;
      gap: 10px;
      align-items: center;
      @extend .f-body-3;
      .help-color {
        width: 15px;
        height: 15px;
        border-radius: 30px;
      }
    }
  }
</style>
