import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, toHandlers as _toHandlers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_EditorContent = _resolveComponent("EditorContent")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.editor)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editor.chain().focus().toggleBold().run())),
                disabled: !_ctx.editor.can().chain().focus().toggleBold().run() || _ctx.disabled,
                class: {
              'is-active': _ctx.editor.isActive('bold'),
              'editor-btn': true,
            }
              }), {
                default: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createElementVNode("span", { class: "mdi mdi-format-bold" }, null, -1)
                ])),
                _: 2
              }, 1040, ["disabled", "class"])
            ]),
            default: _withCtx(() => [
              _cache[20] || (_cache[20] = _createElementVNode("span", null, "Bold", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editor.chain().focus().toggleItalic().run())),
                disabled: !_ctx.editor.can().chain().focus().toggleItalic().run() || _ctx.disabled,
                class: {
              'is-active': _ctx.editor.isActive('italic'),
              'editor-btn': true,
            }
              }), {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createElementVNode("span", { class: "mdi mdi-format-italic" }, null, -1)
                ])),
                _: 2
              }, 1040, ["disabled", "class"])
            ]),
            default: _withCtx(() => [
              _cache[22] || (_cache[22] = _createElementVNode("span", null, "Italic", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editor.chain().focus().toggleStrike().run())),
                disabled: !_ctx.editor.can().chain().focus().toggleStrike().run() || _ctx.disabled,
                class: {
              'is-active': _ctx.editor.isActive('strike'),
              'editor-btn': true,
            }
              }), {
                default: _withCtx(() => _cache[23] || (_cache[23] = [
                  _createElementVNode("span", { class: "mdi mdi-format-strikethrough" }, null, -1)
                ])),
                _: 2
              }, 1040, ["disabled", "class"])
            ]),
            default: _withCtx(() => [
              _cache[24] || (_cache[24] = _createElementVNode("span", null, "Strike", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editor.chain().focus().toggleCode().run())),
                disabled: !_ctx.editor.can().chain().focus().toggleCode().run() || _ctx.disabled,
                class: {
              'is-active': _ctx.editor.isActive('code'),
              'editor-btn': true,
            }
              }), {
                default: _withCtx(() => _cache[25] || (_cache[25] = [
                  _createElementVNode("span", { class: "mdi mdi-code-not-equal-variant" }, null, -1)
                ])),
                _: 2
              }, 1040, ["disabled", "class"])
            ]),
            default: _withCtx(() => [
              _cache[26] || (_cache[26] = _createElementVNode("span", null, "Code", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                disabled: _ctx.disabled,
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editor.chain().focus().unsetAllMarks().run())),
                class: "editor-btn"
              }), {
                default: _withCtx(() => _cache[27] || (_cache[27] = [
                  _createElementVNode("span", { class: "mdi mdi-format-clear" }, null, -1)
                ])),
                _: 2
              }, 1040, ["disabled"])
            ]),
            default: _withCtx(() => [
              _cache[28] || (_cache[28] = _createElementVNode("span", null, "Clear", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.editor.chain().focus().setParagraph().run())),
                class: {
              'is-active': _ctx.editor.isActive('paragraph'),
              'editor-btn': true,
            },
                disabled: _ctx.disabled
              }), {
                default: _withCtx(() => _cache[29] || (_cache[29] = [
                  _createElementVNode("span", { class: "mdi mdi-format-paragraph" }, null, -1)
                ])),
                _: 2
              }, 1040, ["class", "disabled"])
            ]),
            default: _withCtx(() => [
              _cache[30] || (_cache[30] = _createElementVNode("span", null, "Paragraph", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.editor.chain().focus().toggleHeading({ level: 1 }).run())),
                class: {
              'is-active': _ctx.editor.isActive('heading', { level: 1 }),
              'editor-btn': true,
            },
                disabled: _ctx.disabled
              }), {
                default: _withCtx(() => _cache[31] || (_cache[31] = [
                  _createTextVNode(" h1 ")
                ])),
                _: 2
              }, 1040, ["class", "disabled"])
            ]),
            default: _withCtx(() => [
              _cache[32] || (_cache[32] = _createElementVNode("span", null, "H1", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.editor.chain().focus().toggleHeading({ level: 2 }).run())),
                class: {
              'is-active': _ctx.editor.isActive('heading', { level: 2 }),
              'editor-btn': true,
            },
                disabled: _ctx.disabled
              }), {
                default: _withCtx(() => _cache[33] || (_cache[33] = [
                  _createTextVNode(" h2 ")
                ])),
                _: 2
              }, 1040, ["class", "disabled"])
            ]),
            default: _withCtx(() => [
              _cache[34] || (_cache[34] = _createElementVNode("span", null, "H2", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.editor.chain().focus().toggleHeading({ level: 3 }).run())),
                class: {
              'is-active': _ctx.editor.isActive('heading', { level: 3 }),
              'editor-btn': true,
            },
                disabled: _ctx.disabled
              }), {
                default: _withCtx(() => _cache[35] || (_cache[35] = [
                  _createTextVNode(" h3 ")
                ])),
                _: 2
              }, 1040, ["class", "disabled"])
            ]),
            default: _withCtx(() => [
              _cache[36] || (_cache[36] = _createElementVNode("span", null, "H3", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.editor.chain().focus().toggleHeading({ level: 4 }).run())),
                class: {
              'is-active': _ctx.editor.isActive('heading', { level: 4 }),
              'editor-btn': true,
            },
                disabled: _ctx.disabled
              }), {
                default: _withCtx(() => _cache[37] || (_cache[37] = [
                  _createTextVNode(" h2 ")
                ])),
                _: 2
              }, 1040, ["class", "disabled"])
            ]),
            default: _withCtx(() => [
              _cache[38] || (_cache[38] = _createElementVNode("span", null, "h2", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.editor.chain().focus().toggleHeading({ level: 5 }).run())),
                class: {
              'is-active': _ctx.editor.isActive('heading', { level: 5 }),
              'editor-btn': true,
            },
                disabled: _ctx.disabled
              }), {
                default: _withCtx(() => _cache[39] || (_cache[39] = [
                  _createTextVNode(" h5 ")
                ])),
                _: 2
              }, 1040, ["class", "disabled"])
            ]),
            default: _withCtx(() => [
              _cache[40] || (_cache[40] = _createElementVNode("span", null, "H5", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.editor.chain().focus().toggleHeading({ level: 6 }).run())),
                class: {
              'is-active': _ctx.editor.isActive('heading', { level: 6 }),
              'editor-btn': true,
            },
                disabled: _ctx.disabled
              }), {
                default: _withCtx(() => _cache[41] || (_cache[41] = [
                  _createTextVNode(" h6 ")
                ])),
                _: 2
              }, 1040, ["class", "disabled"])
            ]),
            default: _withCtx(() => [
              _cache[42] || (_cache[42] = _createElementVNode("span", null, "H6", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.editor.chain().focus().toggleBulletList().run())),
                class: {
              'is-active': _ctx.editor.isActive('bulletList'),
              'editor-btn': true,
            },
                disabled: _ctx.disabled
              }), {
                default: _withCtx(() => _cache[43] || (_cache[43] = [
                  _createElementVNode("span", { class: "mdi mdi-format-list-bulleted" }, null, -1)
                ])),
                _: 2
              }, 1040, ["class", "disabled"])
            ]),
            default: _withCtx(() => [
              _cache[44] || (_cache[44] = _createElementVNode("span", null, "Bullet List", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.editor.chain().focus().toggleOrderedList().run())),
                class: {
              'is-active': _ctx.editor.isActive('orderedList'),
              'editor-btn': true,
            },
                disabled: _ctx.disabled
              }), {
                default: _withCtx(() => _cache[45] || (_cache[45] = [
                  _createElementVNode("span", { class: "mdi mdi-format-list-numbered" }, null, -1)
                ])),
                _: 2
              }, 1040, ["class", "disabled"])
            ]),
            default: _withCtx(() => [
              _cache[46] || (_cache[46] = _createElementVNode("span", null, "Ordered List", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.editor.chain().focus().toggleCodeBlock().run())),
                class: {
              'is-active': _ctx.editor.isActive('codeBlock'),
              'editor-btn': true,
            },
                disabled: _ctx.disabled
              }), {
                default: _withCtx(() => _cache[47] || (_cache[47] = [
                  _createElementVNode("span", { class: "mdi mdi-code-braces" }, null, -1)
                ])),
                _: 2
              }, 1040, ["class", "disabled"])
            ]),
            default: _withCtx(() => [
              _cache[48] || (_cache[48] = _createElementVNode("span", null, "Code Block", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                disabled: _ctx.disabled,
                onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.editor.chain().focus().setHorizontalRule().run())),
                class: "editor-btn"
              }), {
                default: _withCtx(() => _cache[49] || (_cache[49] = [
                  _createElementVNode("span", { class: "mdi mdi-minus" }, null, -1)
                ])),
                _: 2
              }, 1040, ["disabled"])
            ]),
            default: _withCtx(() => [
              _cache[50] || (_cache[50] = _createElementVNode("span", null, "Horizontal Line", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                disabled: _ctx.disabled,
                onClick: _ctx.setHyperLink,
                class: {
              'is-active': _ctx.editor.isActive('link'),
              'editor-btn': true,
            }
              }), {
                default: _withCtx(() => _cache[51] || (_cache[51] = [
                  _createElementVNode("span", { class: "mdi mdi-link" }, null, -1)
                ])),
                _: 2
              }, 1040, ["disabled", "onClick", "class"])
            ]),
            default: _withCtx(() => [
              _cache[52] || (_cache[52] = _createElementVNode("span", null, "Set Hyper Link", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.editor.chain().focus().unsetLink().run())),
                disabled: !_ctx.editor.isActive('link') || _ctx.disabled,
                class: "editor-btn"
              }), {
                default: _withCtx(() => _cache[53] || (_cache[53] = [
                  _createElementVNode("span", { class: "mdi mdi-link-off" }, null, -1)
                ])),
                _: 2
              }, 1040, ["disabled"])
            ]),
            default: _withCtx(() => [
              _cache[54] || (_cache[54] = _createElementVNode("span", null, "Remove Hyper Link", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.editor.chain().focus().undo().run())),
                disabled: !_ctx.editor.can().chain().focus().undo().run() || _ctx.disabled,
                class: "editor-btn"
              }), {
                default: _withCtx(() => _cache[55] || (_cache[55] = [
                  _createElementVNode("span", { class: "mdi mdi-undo" }, null, -1)
                ])),
                _: 2
              }, 1040, ["disabled"])
            ]),
            default: _withCtx(() => [
              _cache[56] || (_cache[56] = _createElementVNode("span", null, "Undo", -1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_tooltip, { top: "" }, {
            activator: _withCtx(({ on, attrs }) => [
              _createVNode(_component_v_btn, _mergeProps(attrs, _toHandlers(on), {
                onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.editor.chain().focus().redo().run())),
                disabled: !_ctx.editor.can().chain().focus().redo().run() || _ctx.disabled,
                class: "editor-btn"
              }), {
                default: _withCtx(() => _cache[57] || (_cache[57] = [
                  _createElementVNode("span", { class: "mdi mdi-redo" }, null, -1)
                ])),
                _: 2
              }, 1040, ["disabled"])
            ]),
            default: _withCtx(() => [
              _cache[58] || (_cache[58] = _createElementVNode("span", null, "Redo", -1))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_EditorContent, {
      class: "editor-content",
      editor: _ctx.editor
    }, null, 8, ["editor"])
  ]))
}