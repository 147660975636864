<template>
  <v-menu v-model="menu" :width="customDate ? 'auto' : 300" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <div v-bind="props" class="date-dropdown-field-label">
        <v-icon>mdi-calendar-month-outline</v-icon>
        <span>{{ selectedLabel }}</span>
      </div>
    </template>

    <v-card>
      <div class="date-dropdown-field-body">
        <v-expand-transition>
          <v-list class="p-0" v-if="!customDate">
            <h6>Recommended</h6>
            <v-list-item @click="setRange('last7days')">
              <div class="d-flex justify-space-between">
                <span class="f-body-small">Last 7 days</span>
                <span class="f-body-extra-small">{{ formatRange(last7Days) }}</span>
              </div>
            </v-list-item>
            <v-list-item @click="setRange('thisMonth')">
              <div class="d-flex justify-space-between">
                <span class="f-body-small">This month</span>
                <span class="f-body-extra-small">{{ formatRange(thisMonth) }}</span>
              </div>
            </v-list-item>
            <v-list-item @click="setRange('lastMonth')">
              <div class="d-flex justify-space-between">
                <span class="f-body-small">Last month</span>
                <span class="f-body-extra-small">{{ formatRange(lastMonth) }}</span>
              </div>
            </v-list-item>
            <v-subheader class="pt-4 d-block">Calendar months</v-subheader>
            <v-list-item @click="setRange('thisMonth')">
              <div class="d-flex justify-space-between">
                <span class="f-body-small">This month</span>
                <span class="f-body-extra-small">{{ formatRange(thisMonth) }}</span>
              </div>
            </v-list-item>
            <v-list-item @click="setRange('thisQuarter')">
              <div class="d-flex justify-space-between">
                <span class="f-body-small">This quarter</span>
                <span class="f-body-extra-small">{{ formatRange(thisQuarter) }}</span>
              </div>
            </v-list-item>
            <v-list-item @click="setRange('thisYear')">
              <div class="d-flex justify-space-between">
                <span class="f-body-small">This year</span>
                <span class="f-body-extra-small">{{ formatRange(thisYear) }}</span>
              </div>
            </v-list-item>
            <v-list-item @click="setRange('lastMonth')">
              <div class="d-flex justify-space-between">
                <span class="f-body-small">Last month</span>
                <span class="f-body-extra-small">{{ formatRange(lastMonth) }}</span>
              </div>
            </v-list-item>
            <v-list-item @click="setRange('last3Months')">
              <div class="d-flex justify-space-between">
                <span class="f-body-small">Last 3 months</span>
                <span class="f-body-extra-small">{{ formatRange(last3Months) }}</span>
              </div>
            </v-list-item>
            <v-list-item @click="setRange('last6Months')">
              <div class="d-flex justify-space-between">
                <span class="f-body-small">Last 6 months</span>
                <span class="f-body-extra-small">{{ formatRange(last6Months) }}</span>
              </div>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="customDate = true">
              <div class="d-flex align-center">
                <span class="f-body-small">Custom date range</span>
                <v-icon>mdi-chevron-right</v-icon>
              </div>
            </v-list-item>
          </v-list>
        </v-expand-transition>
        <v-expand-transition mode="out-in">
          <v-card v-if="customDate" flat>
            <v-btn @click="customDate = false">Back</v-btn>
            <DatePicker v-model="customRange" dark inline range multi-calendars @update:model-value="setCustomRange" :enable-time-picker="false" />
          </v-card>
        </v-expand-transition>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
  import moment from "moment"
  import DatePicker from "@vuepic/vue-datepicker"
  import "@vuepic/vue-datepicker/dist/main.css"
  export default {
    components: { DatePicker },
    data() {
      return {
        menu: false,
        selectedLabel: "Feb 2025",
        customDate: false,
        customRange: [],
        last7Days: [moment().subtract(7, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        thisMonth: [moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")],
        thisQuarter: [moment().startOf("quarter").format("YYYY-MM-DD"), moment().endOf("quarter").format("YYYY-MM-DD")],
        thisYear: [moment().startOf("year").format("YYYY-MM-DD"), moment().endOf("year").format("YYYY-MM-DD")],
        lastMonth: [moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")],
        last3Months: [moment().subtract(3, "months").startOf("month").format("YYYY-MM-DD"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")],
        last6Months: [moment().subtract(6, "months").startOf("month").format("YYYY-MM-DD"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")],
      }
    },
    mounted() {
      this.setRange("thisMonth")
    },
    methods: {
      setRange(type) {
        const ranges = {
          last7days: this.last7Days,
          thisMonth: this.thisMonth,
          thisQuarter: this.thisQuarter,
          thisYear: this.thisYear,
          lastMonth: this.lastMonth,
          last3Months: this.last3Months,
          last6Months: this.last6Months,
        }
        this.updateSelection(ranges[type])
      },
      setCustomRange() {
        if (this.customRange.length === 2) {
          this.updateSelection(this.customRange)
          this.customDate = false
        }
      },
      updateSelection(range) {
        this.selectedLabel = this.formatRange(range)
        this.menu = false
        this.$emit("selectedDate", { startDate: moment(range[0]).format("YYYY-MM-DD"), endDate: moment(range[1]).format("YYYY-MM-DD") })
      },
      formatRange(range) {
        const start = moment(range[0])
        const end = moment(range[1])

        const startFormat = start.year() === moment().year() ? "MMM D" : "MMM D, YYYY"

        return `${start.format(startFormat)} - ${end.format("MMM D, YYYY")}`
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/style.scss";
  .date-dropdown-field-label {
    display: flex;
    gap: 4px;
    align-items: center;
    @extend .b-r-10;
    border: 1px solid $c_dim_gray;
    padding: 8px;
    cursor: pointer;
  }
  .date-dropdown-field-body {
    padding: 10px;
    .v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
      padding: 0 0 0 8px !important;
      min-height: 35px;
    }
  }
</style>
