<template>
  <v-card class="hidden-md-and-up price-calculator-mobile">
    <v-card-text>
      <PriceCalculator :feePerHour="getVmPerHourPrice" :currency="selectedCurrency" />
    </v-card-text>
  </v-card>
  <div class="header-title">
    <h2>Create Your Smart Workbench</h2>
    <span class="f-body-medium c-silver-2">
      Its easy to customise and set up a new virtual desktop. Your new virtual desktop can be assigned to your team member anywhere in the world
    </span>
  </div>

  <v-form ref="frmVD" v-model="vdValid">
    <v-card flat class="mt-2" :loading="loading" :disabled="loading">
      <CardTitle title="Create a Virtual Desktop">
        <template v-slot:sub-title>
          <div class="d-flex justify-space-between w-100">
            <div class="d-flex flex-column">
              <span class="c-silver-2">Create a new Virtual Desktop, choose your configuration.</span>
              <a><span class="c-jordy-blue">What is the right Virtual Desktop for me?</span></a>
            </div>
            <div class="d-flex ga-2">
              <SelectField :rules="[rules.required]" :items="currencies" v-model="selectedCurrency" label="Currency" placeholder="Currency" />
              <div class="tax-input">
                <NumberField :rules="[rules.required]" v-model="selectedTax" min="0" label="Tax" />
              </div>
            </div>
          </div>
        </template>
      </CardTitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <VirtualDesktopAddon
              :virtualDesktopSpec="model.Addons?.VirtualDesktopAddon"
              :diskValues="diskValues"
              :cpuValues="cpuValues"
              :ramValues="ramValues"
              :isAddon="false"
              :fullScreen="true" />
          </v-col>
          <v-col cols="6" class="hidden-sm-and-down">
            <div class="d-flex justify-end">
              <PriceCalculator class="price-calculator-desktop" :feePerHour="getVmPerHourPrice" :currency="selectedCurrency" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat class="mt-2" :loading="loading" :disabled="loading">
      <CardTitle title="Team Member">
        <template v-slot:sub-title>
          <span class="c-silver-2">Assign your new virtual desktop to a team member.</span>
          <span class="c-silver-2">You can assign it to an existing team member, or add a new team member</span>
        </template>
      </CardTitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <AutoCompleteField label="Job Role" item-title="Name" item-value="id" :rules="[rules.required]" :items="getJobRoles" v-model="model.JobRoleId" outlined />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <TextField :rules="[rules.EmailCheck]" label="Email" v-model="model.TmEmail" @focousout="validateTeammember" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <AutoCompleteField
              :rules="[tmDetailsDisabled || rules.required]"
              label="Country of Residence"
              item-title="Name"
              item-value="Id"
              :items="countries"
              v-model="model.TmCountry"
              :disabled="tmDetailsDisabled" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="4">
            <TextField :rules="[tmDetailsDisabled || rules.required]" label="First Name" v-model="model.TmFirstName" :disabled="tmDetailsDisabled" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <TextField :rules="[tmDetailsDisabled || rules.required]" label="Last Name" v-model="model.TmLastName" :disabled="tmDetailsDisabled" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <!-- <PhoneField enable-searching-country :disabled="tmDetailsDisabled" :rules="[tmDetailsDisabled]" label="Mobile Number" v-model="model.TmMobile" /> -->
            <TextField :disabled="tmDetailsDisabled" :rules="[tmDetailsDisabled || rules.phoneNumber]" label="Mobile Number" v-model="model.TmMobile" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat class="mt-2" :loading="loading" :disabled="loading">
      <CardTitle title="Engagement Details" />
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <DateInputField
              label="Start date"
              :rules="[rules.required]"
              first-day-of-week="1"
              v-model="model.CommitmentStartDate"
              hide-actions
              placeholder="Start date"
              prepend-icon=""
              :min="getTodayDate" />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <DateInputField
              label="End date"
              :rules="[rules.required]"
              first-day-of-week="1"
              v-model="model.CommitmentEndDate"
              hide-actions
              placeholder="End date"
              prepend-icon=""
              :min="getMinDate" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <ComboBoxField
              :rules="[rules.required]"
              label="Project / Team Name"
              :items="projects"
              item-title="Name"
              item-value="Name"
              v-model="model.ProjectName"
              :return-object="false" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
  <v-card-actions class="justify-center mt-5">
    <v-btn class="primary_btn" prependIcon="mdi-content-save" @click="showCompleteSetupModal" :loading="loading" :disabled="loading">Create</v-btn>
  </v-card-actions>
  <v-dialog scrollable v-if="showCompleteSetup" v-model="showCompleteSetup" max-width="1100" @keydown.esc="showCompleteSetup = false">
    <CompleteSetup
      :model="model"
      :projectLeads="projectLeads"
      :portfolioManagers="portfolioManagers"
      :publicHolidayZones="publicHolidayZones"
      :cpuValues="cpuValues"
      :ramValues="ramValues"
      :diskValues="diskValues"
      :billableItems="billableItems"
      :selectedCurrency="selectedCurrency"
      :selectedTax="selectedTax"
      @cancel="closeCompleteSetupModal" />
  </v-dialog>
</template>
<script lang="ts">
  import { defineComponent, toRaw } from "vue"
  import VirtualDesktopAddon from "shared-components/src/components/Commitment/Addons/VirtualDesktopAddon.vue"
  import rules from "shared-components/src/utils/validations"
  import Details from "shared-components/src/models/Details"
  import DetailsService from "@/services/DetailsService"
  import ProjectService from "@/services/ProjectService"
  import PortfolioManagerService from "@/services/PortfolioManagerService"
  import store from "@/store"
  import { ClientCreateVMRequest, ClientTeammemberApi, CountryApi, CountryModel, VirtualDesktopSpec } from "shared-components/src/services/openApi/api"
  import Project from "shared-components/src/models/Project"
  import PriceCalculator from "./PriceCalculator.vue"
  import CompleteSetup from "./CompleteSetup.vue"
  import NumberField from "shared-components/src/components/Fields/NumberField.vue"
  import { ClientBillableItem } from "shared-components/src/services/openApi"
  import { ClientBillableItemApi } from "shared-components/src/services/openApi"

  export default defineComponent({
    components: { VirtualDesktopAddon, PriceCalculator, CompleteSetup },
    data() {
      return {
        snack: true,
        showCompleteSetup: false,
        lastEmailValidated: "",
        projectLeads: [] as Array<{ id: string; Name: string }>,
        portfolioManagers: [] as Array<{ id: string; Name: string }>,
        publicHolidayZones: [] as Array<{ id: string; Name: string }>,
        billableItems: [] as Array<ClientBillableItem>,
        projects: [] as Project[],
        vdValid: false,
        loading: false,
        rules,
        tmDetailsDisabled: true,
        countries: [] as CountryModel[],
        ramValues: {
          0: "8",
          1: "16",
          2: "24",
          3: "32",
        },
        cpuValues: {
          0: "6",
          1: "8",
          2: "10",
        },
        diskValues: {
          0: "128",
          1: "256",
          2: "384",
          3: "512",
        },
        details: [] as Details[],
        model: {
          Addons: {
            CodeScanAddon: { Enabled: false },
            TdmAddon: { Enabled: false },
            TimesheetProcessingAddon: { Enabled: false },
            PayrollAddon: { Enabled: false },
            VirtualDesktopAddon: { Enabled: true },
            LineManagerAddon: { Enabled: false },
            ContractAddon: { Enabled: false },
            CodeClanFundAddon: { Enabled: false },
          },
        } as ClientCreateVMRequest,
        selectedCurrency: "AUD",
        selectedTax: 0,
        currencies: [
          { title: "AUD", value: "AUD" },
          { title: "PHP", value: "PHP", props: { disabled: true } },
          { title: "MYR", value: "MYR", props: { disabled: true } },
          { title: "USD", value: "USD", props: { disabled: true } },
          { title: "SGD", value: "SGD", props: { disabled: true } },
        ],
      }
    },
    async mounted() {
      this.loading = true
      await this.loadDetailsList()
      await this.loadBillableItems()
      await this.loadProjectLead()
      await this.loadPublicHolidayZone()
      await this.fetchProjects()
      await this.fetchCountries()
      if (this.isClient) {
        await this.loadPortfolioManager()
      }
      this.loading = false
    },
    methods: {
      async loadBillableItems() {
        this.billableItems = (await new ClientBillableItemApi().clientGetBillableItems()).data
      },
      async fetchCountries() {
        this.countries = (await new CountryApi().clientGetCountries()).data
      },
      async validateTeammember() {
        if (this.model.TmEmail && this.model.TmEmail != "" && this.lastEmailValidated != this.model.TmEmail) {
          this.model.TmFirstName = ""
          this.model.TmLastName = ""
          this.model.TmCountry = ""
          this.model.TmMobile = ""
          this.lastEmailValidated = this.model.TmEmail
          const isValidEmail = this.rules.Email(this.model.TmEmail)
          if (isValidEmail == "") {
            this.loading = true
            try {
              const response = (await new ClientTeammemberApi().clientValidateTeammember(this.model.TmEmail)).data as any
              if (response.Id) {
                this.model.TmFirstName = response.FirstName
                this.model.TmLastName = response.LastName
                this.model.TmCountry = response.Country
                this.model.TmMobile = response.Mobile
                this.tmDetailsDisabled = true
              } else {
                this.model.TmFirstName = ""
                this.model.TmLastName = ""
                this.model.TmCountry = ""
                this.model.TmMobile = ""
                this.tmDetailsDisabled = false
              }
            } catch (error: any) {
              this.tmDetailsDisabled = true
            } finally {
              this.loading = false
            }
          }
        }
      },
      async showCompleteSetupModal() {
        const isVdValid = (await (this.$refs.frmVD as any).validate()).valid
        if (isVdValid) {
          this.showCompleteSetup = true
        }
      },
      closeCompleteSetupModal() {
        this.showCompleteSetup = false
      },
      async fetchProjects() {
        this.projects = await ProjectService.getList()
      },
      async loadDetailsList() {
        this.details = await DetailsService.getDetails()
      },
      async loadProjectLead() {
        this.projectLeads = (await ProjectService.getProjectLeadList())
          .filter((c) => c.FirstName || c.LastName)
          .map((item) => {
            return { id: item.id, Name: item.FirstName + " " + item.LastName }
          })
      },
      async loadPortfolioManager() {
        this.portfolioManagers = (await PortfolioManagerService.getPortfolioManagerList())
          .filter((c) => c.FirstName || c.LastName)
          .map((item) => {
            return {
              id: item.id ?? "",
              Name: item.FirstName + " " + item.LastName,
            }
          })
      },
      async loadPublicHolidayZone() {
        this.publicHolidayZones = (await ProjectService.getPublicHolidayZoneList()).map((item: any) => {
          return { id: item.Id, Name: item.Title }
        })
      },
    },
    computed: {
      getTodayDate() {
        return new Date().toDateString()
      },
      getMinDate() {
        if (this.model.CommitmentStartDate && this.model.CommitmentEndDate && this.model.CommitmentStartDate > this.model.CommitmentEndDate) {
          this.model.CommitmentEndDate = this.model.CommitmentStartDate
        }
        return !this.model.CommitmentStartDate || this.model.CommitmentStartDate == "" ? this.getTodayDate : this.model.CommitmentStartDate
      },
      isClient(): boolean {
        return store.getters.userIsClient
      },
      getJobRoles() {
        return toRaw(this.details).filter((item: any) => item.Type == "JobRole")
      },
      getVmPerHourPrice() {
        if (this.model && this.model.Addons && this.model.Addons.VirtualDesktopAddon) {
          const baseFee = this.billableItems?.find((x: any) => x.Code == "VDICR")?.Fee ?? 0
          const cpu = Number(Object.values(this.cpuValues)[this.model?.Addons?.VirtualDesktopAddon?.Cpu ?? 0])
          const ram = Number(Object.values(this.ramValues)[this.model?.Addons?.VirtualDesktopAddon?.Ram ?? 0])
          const disk = Number(Object.values(this.diskValues)[this.model?.Addons?.VirtualDesktopAddon?.Disk ?? 0])

          const cpuExtraFee = ((cpu - 6) / 200).toFixed(2)
          const ramExtraFee = ((ram - 8) / 800).toFixed(2)
          const diskExtraFee = ((disk - 128) / 12800).toFixed(2)

          return baseFee + Number(cpuExtraFee) + Number(ramExtraFee) + Number(diskExtraFee)
        }
      },
    },
  })
</script>
<style scoped lang="scss">
  @import "node_modules/shared-components/assets/style/style.scss";
  .header-title {
    padding: 0 24px;
  }
  .price-calculator-desktop {
    max-width: 550px;
    margin: 0;
  }
  .tax-input {
    width: 100px !important;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .price-calculator-mobile {
      position: sticky;
      top: 72px;
      z-index: 10;
      background-color: $c_onyx !important;
    }
  }
</style>
