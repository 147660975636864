<template>
  <v-row :class="[isTotal ? 'text-white pb-2' : '']">
    <v-col cols="12" :class="{ 'total-cost-container': !isOrderItem && !isTotal }">
      <div v-if="!isOrderItem" class="d-flex flex-column ga-2">
        <h2>Estimated {{ isTotal ? "Total" : "" }} Monthly Cost {{ currency }} {{ getPerMonthPrice }}</h2>
        <h2>({{ currency }} {{ getPerHourPrice }} per work hour)</h2>
      </div>
      <div v-else class="d-flex flex-column ga-1">
        <span class="f-sub-heading c-white">Estimated Monthly Cost {{ currency }} {{ getPerMonthPrice }}</span>
        <span class="f-sub-heading c-white">({{ currency }} {{ getPerHourPrice }} per work hour)</span>
      </div>

      <span class="c-silver-2 f-body-1 hidden-sm-and-down" v-if="!isOrderItem && !isTotal">
        Costs are estimated based on usage time. All smart workbench virtual desktops are dedicated 24/7, but you only get charged for the hours you use.
      </span>
      <v-tooltip :text="getPriceTooltip()" max-width="300" v-if="!isOrderItem && !isTotal">
        <template v-slot:activator="{ props }">
          <span v-bind="props" class="f-body-1 hidden-sm-and-down c-jordy-blue">More Information on transparent pricing</span>
        </template>
      </v-tooltip>
    </v-col>
  </v-row>
</template>
<script lang="ts">
  import { defineComponent } from "vue"

  export default defineComponent({
    props: ["isOrderItem", "isTotal", "feePerHour", "currency"],
    data() {
      return {
        hoursPerMonth: 176,
      }
    },
    async mounted() {},
    methods: {
      getTotalOfHoursPerMonth(price: number) {
        return price * this.hoursPerMonth
      },
      getPriceTooltip() {
        return !this.isOrderItem
          ? "Pricing is calculated based on the hours of work not the uptime, even though Virtual Desktops are dedicated to the user 24 hours a day 7 days a week. For example, the cost of a Virtual Desktop instance that is dedicated to a user who works fulltime, is calculated based on 40 hours per week."
          : "The cost of timesheet management is calculated based on the actual work hours logged in the timesheet by the Team Member. For example, if they   log 40 hours per week in the timesheet, the timesheet management cost for that week will be calculated based on 40 hours"
      },
    },
    computed: {
      getPerHourPrice() {
        if (this.feePerHour) {
          return this.feePerHour.toFixed(2)
        }
        return 0
      },
      getPerMonthPrice() {
        if (this.feePerHour) {
          return this.getTotalOfHoursPerMonth(this.feePerHour).toFixed(2)
        }
        return 0
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/style.scss";
  .total-cost-container {
    display: flex;
    flex-direction: column;
    padding: 27px 44px;
    border: 1px solid;
    gap: 16px;
    @extend .b-r-10;
  }
  .v-theme--dark {
    .total-cost-container {
      border-color: $c_dim_gray;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .total-cost-container {
      padding: 16px 32px;
      border: none;
    }
  }
</style>
