<template>
  <div class="tm-item-object" :class="{ 'active-tm': selectedTeammemberId == teammember.id }">
    <div class="item-details">
      <div class="item-header">
        <div class="item-sub-header">
          <div class="item-tm-detail-box">
            <div class="d-flex ga-2 align-center">
              <Avatar :Name="teammember.fullName" :Photo="teammember.photoUrl" class="item-tm-detail-avatar" />
              <div class="item-tm-detail-name">
                <h6>{{ teammember.fullName }}</h6>
                <div class="item-tm-detail-role" v-if="teammember.jobRole && teammember.jobRole != ''">
                  <v-icon class="item-tm-detail-icon" v-tooltip="teammember.jobRole">{{ teammember.jobRoleIcon }}</v-icon>
                  <span class="f-body-small item-tm-detail-role-text">{{ teammember.jobTitle && teammember.jobTitle != "" ? teammember.jobTitle : "-" }}</span>
                </div>
              </div>
            </div>

            <div class="item-tm-detail-productivity">
              <span class="f-body-extra-small">Productivity Level</span>
              <h4>A</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="item-notif-container">
        <AlertBox :warnings="warnings" :infos="infos" :urgents="urgents" />
      </div>
      <div class="item-code-details-container">
        <div class="item-code-details-box">
          <span class="body-medium-small c-white">{{ tmHours }}</span>
          <span class="body-medium-small c-silver-2">Hour(s)</span>
        </div>
        <div class="d-flex ga-1 align-center">
          <span class="body-medium-small c-white">{{ tmLines }}</span>
          <span class="body-medium-small c-silver-2">Lines</span>
        </div>
      </div>
      <v-btn class="select-tm-btn" prepend-icon="mdi-check" @click="selectTm">{{ selectedTeammemberId == teammember.id ? "Selected" : "Select Team Member" }}</v-btn>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import Avatar from "@/components/Avatar.vue"
  import "chartjs-adapter-date-fns"
  import AlertBox from "./AlertBox.vue"

  export default defineComponent({
    components: {
      AlertBox,
      Avatar,
    },
    props: ["teammember", "line", "hours", "warnings", "infos", "urgents", "selectedTeammemberId"],
    data() {
      return {
        loading: false,
        tmHours: this.hours,
        tmLines: this.line,
      }
    },
    async mounted() {},
    methods: {
      selectTm() {
        this.teammember.isClicked = !this.teammember.isClicked
        this.$emit("selectTm")
      },
    },
    watch: {
      selectedTeammemberId: {
        immediate: true,
        handler(newval: any) {
          if (newval != this.teammember.id) {
            this.teammember.isClicked = false
          }
        },
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/style.scss";

  .item-expired {
    background-color: $c_platinum !important;
    color: $c_imperial_red;
    text-align: center;
    font-weight: 600;
    padding: 5px;
    @extend .b-r-5;
  }
  .tm-item-object {
    align-items: center;
    background-color: $c_eerie_black;
    @extend .b-r-10;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 16px;
    border: solid 1px $c_dim_gray;
    &.active-tm {
      border: solid 1px $c_emerald !important;
    }
    .item-details {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item-sub-header {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 25px;
        }
      }
    }
    .item-notif-container {
      display: flex;
      gap: 12px;
      justify-content: space-between;
    }
    .item-code-details-container {
      display: flex;
      gap: 12px;

      .item-code-details-box {
        display: flex;
        gap: 4px;
        align-items: center;
        width: 32%;
      }
    }
    .chart-container {
      padding: 0;
      width: 100% !important;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .management-tm-bar-chart-header {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: center;
        .security-score {
          @extend .b-r-5;
          gap: 4px;
          display: flex;
          padding-left: 8px;
          padding-right: 4px;
          padding-top: 4px;
          padding-bottom: 4px;
          background-color: $c_onyx;
          align-items: center;
          .security-score-box {
            padding: 2px;
            background: $c_aquamarine;
            @extend .b-r-3;
            color: $c-black;
            width: 17px;
            height: 17px;
            line-height: 13px;
            text-align: center;
          }
        }
      }
      .chart-code-issues {
        display: flex;
        justify-content: space-between;
        padding-left: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 40px;
        background-color: $c_onyx;
        align-items: center;
        @extend .b-r-5;
        .chart-code-issues-title {
          width: 48px;
          text-align: center;
          line-height: 12px;
        }
        .chart-code-issues-item {
          flex: 1;
          text-align: center;
          span {
            cursor: pointer;
            width: 30px;
            height: 16px;
            padding: 2px;
            @extend .b-r-3;
            color: $c_black;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
          &.zero {
            span {
              background-color: $c_battleship_grey_2;
            }
          }
          &.low {
            span {
              background-color: $c_platinum;
            }
          }
          &.medium {
            span {
              background-color: $c_mindaro;
            }
          }
          &.high {
            span {
              background-color: $c_xanthous;
            }
          }
          &.critical {
            span {
              background-color: $c_bittersweet_2;
            }
          }
        }
      }
    }
    .select-tm-btn {
      border: 1px solid $c_jordy_blue;
      color: $c_jordy_blue;
      @extend .b-r-10;
    }
    .item-tm-detail-box {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .item-tm-detail-avatar {
        box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
        width: 36px;
        height: 36px;
      }
      .item-tm-detail-productivity {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $c_jet_3;
        @extend .b-r-3;
        padding: 4px;
        span {
          color: $c_silver_2 !important;
        }
        h4 {
          color: $c_emerald !important;
        }
      }
      .item-tm-detail-name {
        display: flex;
        flex-direction: column;

        .item-tm-detail-role {
          display: flex;
          gap: 4px;
          align-items: center;
          .item-tm-detail-icon {
            font-size: 16px;
            color: $c_white;
          }
          .item-tm-detail-role-text {
            color: $c_silver_2;
          }
        }
      }
    }
  }
</style>
